import { FormField, FormSubmit, FormTextBlock, Icon, Popup } from "@faraday-gitlab/bpfd-portal";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Control, FieldValues, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { Link, useNavigate } from "react-router-dom";
import useCreateChoicesText from "../../hooks/plannerStartupScreen/useCreateChoicesText";
import {
  TRetirementChoiceSchema,
  useRetirementChoiceSchema,
} from "../../hooks/schemas/retirementChoiceSchema";
import useCountries from "../../hooks/useCountries";
import { ButtonAction, ButtonType, RetirementChoiceScreen, YesNoValueEnum } from "../../lib/enum";
import {
  ButtonConfig,
  RetirementChoice,
  RetirementChoiceScreenPayload,
  RetirementChoiceSubmitData,
} from "../../lib/types";
import { deleteScenario, processRetirementChoices } from "../../react-query/mutations";
import { HREF } from "../../routes/routes";
import { isEmptyObject } from "../../utils/objectUtils";
import { retiringSoonResourceLink } from "../../utils/pensionLinks";
import { removeSpace } from "../../utils/string";
import yesNoOptions from "../../utils/YesNoOptions";
import YesNoRadio from "../atoms/YesNoRadio";
import SurrenderConfirmationPopup from "../molecules/retirementChoice/SurrenderConfirmationPopup";
import BankAccountForm from "./BankAccountForm";
import { EMPTY_STRING, PaymentOptionValue } from "../../lib/constants";
import { handleExpiredToken, RetryCallback } from "../../utils/handleExpiredToken";
import StandardFormWrapper from "../templates/StandardFormWrapper";
import { choicesKeys, plannersKeys, togglesKeys } from "../../react-query/query-keys";

const upsertChoiceButtons = [
  ButtonAction.UPSERT_CHOICE_PENSION, // 6
  ButtonAction.UPSERT_CHOICE_AOW, // 7
  ButtonAction.UPSERT_CHOICE_DEFAULT, // 8
  ButtonAction.UPSERT_CHOICE, // 16
];
const surrenderChoiceButtons = [
  ButtonAction.SURRENDER_CHOICE_PENSION, // 10
  ButtonAction.SURRENDER_CHOICE_AOW, // 11
  ButtonAction.SURRENDER_CHOICE_DEFAULT, // 12
];
const deleteChoiceButtons = [ButtonAction.DELETE_CHOICE, ButtonAction.DELETE_CHOICE_SURRENDER]; // 9, 13

interface Props {
  retirementChoice: RetirementChoice;
}

const RetirementChoiceForm: React.FC<Props> = ({ retirementChoice }) => {
  const buttons = useCreateChoicesText(retirementChoice);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const intl = useIntl();
  const { countriesByUserLng } = useCountries();
  const retirementChoiceSchema = useRetirementChoiceSchema();

  const [showMoreAboutTax, setShowMoreAboutTax] = useState(false);
  const [showMoreAboutTaxDetails, setShowMoreAboutTaxDetails] = useState(false);
  const [selectedButtonId, setSelectedButtonId] = useState(0);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [showThankYouMessage, setShowThankYouMessage] = useState(false);
  const [payload, setPayload] = useState<RetirementChoiceSubmitData>({
    data: {} as TRetirementChoiceSchema,
    button: {} as ButtonConfig,
    isSigned: false,
  });
  const { person, screen, participation, entitlementChoice } = retirementChoice;
  const haveRelationship = person?.hasRelationship;
  const hasTaxException = person?.hasTaxException;
  const isDisabled = person?.isDisabled;
  const personalBankAccount = person?.personalBankAccount;
  const hasBankAccount = !!personalBankAccount;
  const isInsideBankAccount = !hasBankAccount || personalBankAccount?.isBankAccountEU;
  const textButtons = buttons.filter((item) => item.type === ButtonType.BUTTON);
  const submitButtons = buttons.filter((item) => item.type === ButtonType.SUBMIT);

  const countriesOptions = countriesByUserLng.map((item) => ({
    value: item.countryCode,
    id: item.countryCode,
    label: item.countryName,
  }));
  const paymentOptions = [
    {
      label: intl.formatMessage({ id: "passing-away.within-eu" }),
      checked: !!isInsideBankAccount,
      value: PaymentOptionValue.InsideEU,
      id: "1",
    },
    {
      label: intl.formatMessage({ id: "passing-away.outside-eu" }),
      checked: !isInsideBankAccount,
      value: PaymentOptionValue.OutsideEU,
      id: "2",
    },
  ];

  const havingPartnerOptions = yesNoOptions(intl, "partner", {
    yes: haveRelationship,
    no: !haveRelationship,
  });
  const taxReductionOptions = yesNoOptions(intl, "tax", { yes: hasTaxException, no: !hasTaxException });
  const disabledOptions = yesNoOptions(intl, "disabled", { yes: hasTaxException, no: !hasTaxException });
  const existingCountryCode = personalBankAccount?.countryCode ?? 0;
  const existingBankLand = !personalBankAccount?.isBankAccountEU
    ? countriesOptions.find((country) => country.value === existingCountryCode.toString())
    : undefined;
  const bankAccount = {
    paymentOptions: paymentOptions,
    bankNumber: personalBankAccount?.bankAccount ?? "",
    bankName: personalBankAccount?.bankName ?? "",
    bankCode: personalBankAccount?.bankBicCode ?? "",
    bankAddress: personalBankAccount?.bankAddress ?? "",
    bankLocation: personalBankAccount?.locationBank ?? "",
    bankLand: existingBankLand,
  };

  const { watch, control, setValue, handleSubmit } = useForm<TRetirementChoiceSchema>({
    mode: "onChange",
    defaultValues: {
      bankAccount: bankAccount,
      havingPartnerOptions,
      taxReductionOptions,
      disabledOptions,
    },
    resolver: zodResolver(retirementChoiceSchema),
  });

  const [watchPaymentOptions, watchPartnerOptions, watchDisabledOptions] = watch([
    "bankAccount.paymentOptions",
    "havingPartnerOptions",
    "disabledOptions",
  ]);

  const isInsideEU = watchPaymentOptions
    ? watchPaymentOptions.find((item) => item.checked)?.value === PaymentOptionValue.InsideEU
    : isInsideBankAccount;
  const showCohabitationLink =
    watchPartnerOptions?.find((item) => item.checked)?.value === YesNoValueEnum.YES;
  const disabledYes = watchDisabledOptions?.find((item) => item.checked)?.value === YesNoValueEnum.YES;

  const {
    mutate: mutateRetirementChoices,
    error: postError,
    isPending,
    reset,
  } = useMutation({
    mutationFn: processRetirementChoices,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: togglesKeys.all,
      });
      if (surrenderChoiceButtons.includes(selectedButtonId)) {
        setShowConfirmationPopup(false);
        setShowThankYouMessage(true);
        await queryClient.invalidateQueries({
          queryKey: choicesKeys.all,
        });
        reset();
        return;
      }
      navigate(HREF.PLANNER);
    },
    onError: async (postError, data): Promise<void> => {
      handleExpiredToken(postError, mutateRetirementChoices as RetryCallback, data);
    },
  });

  const { mutate: mutateDeleteScenario, isPending: isPendingDelete } = useMutation({
    mutationFn: deleteScenario,
    onSuccess: () => {
      queryClient.removeQueries({ queryKey: plannersKeys.all, exact: true });
      navigate(HREF.PLANNER);
    },
    onError: async (postError): Promise<void> => {
      handleExpiredToken(postError, mutateDeleteScenario as RetryCallback);
    },
  });

  const handleAgreedButton = (surrenderPayload?: RetirementChoiceSubmitData) => {
    if (!isEmptyObject(surrenderPayload) && surrenderPayload?.isSigned) {
      handleSubmit(() => onSubmit(surrenderPayload))();
      return;
    }
    setShowConfirmationPopup(false);
  };

  const preSubmitAction = (item: ButtonConfig) => {
    switch (item?.action) {
      case ButtonAction.GO_TO_PLANNER:
        navigate(HREF.PLANNER);
        break;

      case ButtonAction.DELETE_SCENARIO:
        mutateDeleteScenario();
        break;

      default:
        handleSubmit((data) => onSubmit({ data: data, button: item, isSigned: false }))();
        break;
    }
  };

  const setPayloadAction = (selectedButton: ButtonAction, isSigned: boolean): string => {
    switch (true) {
      case selectedButton === ButtonAction.UPDATE_CHOICE:
        return "UPDATE_CHOICE";

      case upsertChoiceButtons.includes(selectedButton):
        return "UPSERT_NEW_CHOICE";

      case deleteChoiceButtons.includes(selectedButton):
        return "DELETE_CHOICE";

      case surrenderChoiceButtons.includes(selectedButton) && isSigned:
        return "PROCESS_SURRENDER_CHOICE";

      default:
        return EMPTY_STRING;
    }
  };

  const setRetirementDate = (selectedButton: ButtonAction): string | null => {
    switch (selectedButton) {
      case ButtonAction.UPSERT_CHOICE_PENSION:
      case ButtonAction.SURRENDER_CHOICE_PENSION:
        return participation.pensionRetirementDate;

      case ButtonAction.UPSERT_CHOICE_AOW:
      case ButtonAction.SURRENDER_CHOICE_AOW:
        return participation.aowRetirementDate;

      case ButtonAction.UPSERT_CHOICE_DEFAULT:
      case ButtonAction.SURRENDER_CHOICE_DEFAULT:
        return participation.defaultRetirementDate;

      case ButtonAction.UPSERT_CHOICE:
        return entitlementChoice?.partlyRetirementStartDate ?? entitlementChoice?.retirementStartDate;

      default:
        return null;
    }
  };

  const onSubmit = (RetirementChoiceSubmitData: RetirementChoiceSubmitData) => {
    const { data, button, isSigned } = RetirementChoiceSubmitData;
    const bankAccount = data?.bankAccount;
    const selectedButton = button?.action;
    setSelectedButtonId(selectedButton);

    if (!isSigned && surrenderChoiceButtons.includes(selectedButton)) {
      setPayload(RetirementChoiceSubmitData);
      setShowConfirmationPopup(true);
      return;
    }

    const payloadAction = setPayloadAction(selectedButton, isSigned);
    const selectedBankAccountType =
      bankAccount?.paymentOptions?.find((i) => i.checked)?.value === PaymentOptionValue.InsideEU;
    const selectedTaxOption = data?.taxReductionOptions?.find((i) => i.checked)?.value === YesNoValueEnum.YES;
    const retirementDate = setRetirementDate(selectedButton);

    const payload: RetirementChoiceScreenPayload = {
      action: payloadAction,
      personalBankAccount: {
        isBankAccountEU: selectedBankAccountType,
        bankAccount: removeSpace(bankAccount?.bankNumber),
        bankName: bankAccount?.bankName,
        bankBicCode: bankAccount?.bankCode,
        bankAddress: bankAccount?.bankAddress,
        locationBank: bankAccount?.bankLocation,
        countryName: bankAccount?.bankLand?.label,
        countryCode: bankAccount?.bankLand?.value,
      },
      isTaxException: selectedTaxOption,
      retirementDate: retirementDate,
    };
    mutateRetirementChoices(payload);
  };

  const onClickTaxIcon = () => {
    setShowMoreAboutTax((prevState) => !prevState);
  };

  const onClickTaxInfoIcon = () => {
    setShowMoreAboutTaxDetails((prevState) => !prevState);
  };

  useEffect(() => {
    if (existingBankLand) {
      setValue("bankAccount.bankLand", existingBankLand);
    }
  }, [existingBankLand, setValue]);

  return (
    <StandardFormWrapper>
      <form>
        {retirementChoice?.screen !== RetirementChoiceScreen.CURRENT_SCENARIO && (
          <>
            {!haveRelationship && (
              <FormField
                id="havingPartnerOptions"
                labelText={<FormattedMessage id="planner.complete-quest.partner-question" />}>
                <YesNoRadio name="havingPartnerOptions" options={havingPartnerOptions} control={control} />
              </FormField>
            )}
            {!haveRelationship && showCohabitationLink && (
              <FormTextBlock
                body={
                  <FormattedMessage
                    id="planner.complete-quest.register-partners"
                    values={{
                      registerLink: (
                        <Link to={HREF.MY_DATA} target="_blank">
                          <FormattedMessage id="planner.complete-quest.alt-text" />
                        </Link>
                      ),
                    }}
                  />
                }
              />
            )}
            <BankAccountForm
              name="bankAccount"
              control={control as unknown as Control<FieldValues>}
              isInsideEU={isInsideEU as boolean}
            />
            <FormField
              id="taxReductionOptions"
              labelHeader={<br />}
              labelText={<FormattedMessage id="planner.complete-quest.tax-question" />}
              showIcon
              onIconClick={onClickTaxIcon}>
              <YesNoRadio name="taxReductionOptions" options={taxReductionOptions} control={control} />
            </FormField>
            <Popup
              open={showMoreAboutTax}
              body={
                <FormattedMessage
                  id="planner.complete-quest.tax-reduction-info"
                  values={{
                    link: (
                      <Link to={retiringSoonResourceLink} target="_blank">
                        <FormattedMessage id="planner.exchange.altText-link" />
                      </Link>
                    ),
                  }}
                />
              }
              onClose={onClickTaxIcon}
              popupVariant="information"
            />
            {isDisabled && (
              <FormField
                id="disabledOptions"
                labelText={<FormattedMessage id="planner.complete-quest.disability-question" />}>
                <YesNoRadio name="disabledOptions" options={disabledOptions} control={control} />
              </FormField>
            )}
            {isDisabled && disabledYes && retirementChoice?.providerPhoneNumber && (
              <FormTextBlock
                body={
                  <FormattedMessage
                    id="planner.complete-quest.contact-info"
                    values={{ phoneNumber: retirementChoice?.providerPhoneNumber }}
                  />
                }
              />
            )}
            <FormTextBlock
              body={
                <>
                  <FormattedMessage id="planner.complete-quest.eligible-tax" />
                  <button
                    style={{ justifySelf: "end", marginRight: 10 }}
                    onClick={(e) => {
                      e.preventDefault();
                      onClickTaxInfoIcon();
                    }}
                    aria-label="More information about tax">
                    <Icon
                      color="fill-black"
                      hoverColor="hover:fill-black group-hover:fill-black"
                      icon="information"
                      size="sm"
                    />
                  </button>
                </>
              }
            />

            <Popup
              open={showMoreAboutTaxDetails}
              body={<FormattedMessage id="planner.complete-quest.eligible-tax-info" />}
              onClose={onClickTaxInfoIcon}
              popupVariant="information"
            />
          </>
        )}
        {screen !== RetirementChoiceScreen.ENTITLEMENT_CHOICE && (
          <FormTextBlock body={<FormattedMessage id="retirement-choice.choices" />} />
        )}
        {textButtons.map((item) => (
          <span key={item.action}>{item.text}</span>
        ))}
        <div style={{ marginTop: "-2.5rem" }}>
          {/* compensates for mandatory gap-y around formSubmit */}
          <FormSubmit
            className="border-none w-full"
            buttons={submitButtons?.map((item) => ({
              size: "sm",
              ariaLabel: "button",
              id: item?.action,
              text: item?.text,
              type: item.type,
              onClick: (event) => {
                event?.preventDefault();
                preSubmitAction(item);
              },
              disabled: isPending || isPendingDelete,
            }))}
            errorMessage={postError?.message}
          />
        </div>
      </form>

      <SurrenderConfirmationPopup
        showPopup={showConfirmationPopup}
        setShowPopup={setShowConfirmationPopup}
        payload={payload}
        onClose={handleAgreedButton}
        isSubmitting={isPending}
      />
      <Popup
        open={showThankYouMessage}
        body={<FormattedMessage id="retirement-choice.surrender.thank-you" />}
        onClose={() => navigate(HREF.HOME)}
        popupVariant="information"
      />
    </StandardFormWrapper>
  );
};

export default RetirementChoiceForm;
