import { z } from "zod";
import { useIntlMessage } from "../useIntlMessage";

export const useUploadingDivorceDocSchema = () => {
  const ALLOWED_FILE_TYPES = "application/pdf";
  const MAX_TOTAL_FILE_SIZE = 50 * 1024 * 1024;
  const intlMessage = useIntlMessage();

  return z.object({
    agreementDocs: z
      .any()
      .refine(
        (files: FileList | null | undefined) => !!files && files?.length > 0,
        intlMessage("validation.file.no-file-select")
      )
      .refine(
        (files: File[]) => {
          if (!files) return true;
          const iPdfOnly = files.findIndex((file) => file.type !== ALLOWED_FILE_TYPES) < 0;
          return iPdfOnly;
        },
        {
          message: intlMessage("validation.file.invalid-file-type"),
        }
      )
      .refine((files: File[]) => files?.length <= 1, intlMessage("validation.file.maximum-file-chosen"))
      .transform((files: File[]) => Array.from(files))
      .refine(
        (files) => {
          const totalSize = files?.reduce((acc, file) => acc + file?.size, 0);
          return totalSize <= MAX_TOTAL_FILE_SIZE;
        },
        { message: intlMessage("validation.file.total-file-size") }
      ),
    agreementDocs2: z
      .any()
      .refine(
        (files: File[]) => {
          if (!files) return true;
          const iPdfOnly = files.findIndex((file) => file.type !== ALLOWED_FILE_TYPES) < 0;
          return iPdfOnly;
        },
        {
          message: intlMessage("validation.file.invalid-file-type"),
        }
      )
      .refine((files: File[]) => files?.length <= 1, intlMessage("validation.file.maximum-file-chosen"))
      .transform((files: File[]) => Array.from(files))
      .refine(
        (files) => {
          const totalSize = files?.reduce((acc, file) => acc + file?.size, 0);
          return totalSize <= MAX_TOTAL_FILE_SIZE;
        },
        { message: intlMessage("validation.file.total-file-size") }
      ) 
      .optional()
  });
};

export type TUploadingDivorceDocSchema = z.infer<ReturnType<typeof useUploadingDivorceDocSchema>>;
