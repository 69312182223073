import { useIntl } from "react-intl";
import { z } from "zod";
import { EARLIER_LATER_FULLY } from "../../lib/constants";
import { LimitsAge, PlannerKeyParams, Retirement, RetirementOptionsLimits } from "../../lib/types";
import { EmploymentOptionKeys, ExchangeType, UseMaximumExchangeAow } from "../../lib/enum";
import { PortalLanguage } from "../../utils/languageLoader";
import { useIntlMessage, useIntlMessageInput, useTypeErrorMessage } from "../useIntlMessage";
import { extractYearAndMonth } from "../../utils/plannerCalendar";
import { numberToMonth } from "../../utils/numberToMonth";

export const usePlannerSchema = (plannerParam: PlannerKeyParams) => {
  const intlMessage = useIntlMessage();
  const intlMessageInput = useIntlMessageInput();
  const intlTypeError = useTypeErrorMessage();
  const locale = useIntl().locale;

  const EUROPEAN_NUMERIC_FORMAT = /^\d{1,3}(?:\.\d{3})*(?:,\d{1,15})?$|^\d+(?:,\d{1,15})?$/;
  const ENGLISH_NUMERIC_FORMAT = /^\d{1,3}(?:,\d{3})*(?:\.\d{1,15})?$|^\d+(?:\.\d{1,15})?$/;

  const formatNumber = locale === PortalLanguage.NL ? EUROPEAN_NUMERIC_FORMAT : ENGLISH_NUMERIC_FORMAT;

  const aowAgeInMonthsAndYears = extractYearAndMonth(plannerParam?.aowAgeInMonths);

  const requiredAgeSchema = (limits: LimitsAge) =>
    z.coerce
      .number(intlTypeError("validation.age.format"))
      .min(limits.currentAge.years, intlMessage("validation.age.range"))
      .max(limits.retirementAge.years, intlMessage("validation.age.retirement-range"))
      .optional();

  const requiredMonthSchema = z.coerce
    .number(intlTypeError("validation.month.format"))
    .min(0, intlMessage("validation.month.range"))
    .max(11, intlMessage("validation.month.range"))
    .optional();

  const requiredStartYearSchema = (retirementYear: number) =>
    z.coerce
      .number(intlTypeError("validation.year.format"))
      .min(new Date().getFullYear(), intlMessage("validation.age.range"))
      .max(retirementYear, intlMessage("validation.age.retirement-range"))
      .optional();

  const requiredSalarySchema = z.coerce
    .number(intlTypeError("validation.salary.format"))
    .min(1, intlMessage("validation.salary.positive"))
    .max(1000000, intlMessage("validation.salary.range"))
    .optional();

  // To be defined in details by user stories of planner
  const requiredHourSchema = z.coerce
    .number(intlTypeError("validation.hours.format"))
    .int(intlMessage("validation.hours.integer"))
    .min(1, intlMessage("validation.hours.range"))
    .max(60, intlMessage("validation.hours.range"))
    .optional();

  const getSurrenderOptionSchema = ({
    limits,
    isActive,
  }: {
    limits: RetirementOptionsLimits;
    isActive: boolean;
  }) =>
    z
      .object({
        isSurrenderSelected: z.boolean(),
        isRefrainFromSurrenderSelected: z.boolean(),
        ageYear: z.coerce.number(intlTypeError("validation.age.format")).optional(),
        ageMonth: z.coerce
          .number(intlTypeError("validation.month.format"))
          .min(0, intlMessage("validation.month.range"))
          .max(11, intlMessage("validation.month.range"))
          .optional(),
        calendarMonth: z
          .object({ value: z.coerce.number(intlTypeError("validation.month.format")) })
          .transform((value) => value.value)
          .optional(),
        calendarYear: z.coerce
          .number(intlTypeError("validation.year.format"))
          .max(
            limits?.latestDefaultRetirementCalendarYear ?? 0,
            intlMessageInput("validation.number.max", { max: limits.latestRetirementCalendarYear.toString() })
          )
          .optional(),
      })
      .superRefine(({ ageYear, ageMonth = 0, isSurrenderSelected, calendarYear }, ctx) => {
        if (!isSurrenderSelected) return;

        if (!ageYear) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: intlMessage("validation.year.required"),
            path: ["ageYear"],
          });
        }
        if (!ageYear) return;

        let minYears = limits?.latestDefaultRetirementAgeYears;
        let minMonths = limits?.earliestRetirementAgeMonths;
        const maxYears = limits?.latestDefaultRetirementAgeYears;
        const maxMonths = limits?.latestDefaultRetirementAgeMonths;
        let minCalendarYear = limits?.earliestRetirementCalendarYear;

        if (isActive) {
          minMonths = limits?.latestDefaultRetirementAgeMonths;
        }

        if (
          !isActive &&
          limits?.earliestSurrenderRetirementAgeMonths &&
          limits?.earliestSurrenderRetirementAgeYears &&
          limits?.earliestSurrenderRetirementCalendarYear
        ) {
          minYears = limits?.earliestSurrenderRetirementAgeYears;
          minMonths = limits?.earliestSurrenderRetirementAgeMonths;
          minCalendarYear = limits?.earliestSurrenderRetirementCalendarYear;
        }

        const isInvalidAgeYear = ageYear < minYears || ageYear > maxYears;
        const isInvalidAgeMonth =
          (ageYear === minYears && ageMonth < minMonths) || (ageYear === maxYears && ageMonth > maxMonths);
        const inValidCalendarYear = calendarYear && calendarYear < minCalendarYear;

        if (isInvalidAgeYear) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: intlMessage("validation.age-years.invalid"),
            path: ["ageYear"],
          });
        }

        if (isInvalidAgeMonth) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: intlMessage("validation.age-months.invalid"),
            path: ["ageMonth"],
          });
        }

        if (inValidCalendarYear) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: intlMessage("validation.age-years.invalid"),
            path: ["calendarYear"],
          });
        }
      });

  const getEarlierOrLatestOptionSchema = ({
    retirement,
    limits,
    hasDisability,
  }: {
    retirement: Retirement
    limits: RetirementOptionsLimits;
    hasDisability: boolean;
  }) =>
    z
      .object({
        isEarlierOrLaterRetirementSelected: z.boolean(),
        fullyOrPartial: z.object({
          id: z.string(),
          label: z.string(),
          value: z.number(intlTypeError("validation.number.format")),
        }),
        partialAgeYear: z.coerce.number(intlTypeError("validation.month.format")).optional(),
        partialAgeMonth: z.coerce
          .number(intlTypeError("validation.month.format"))
          .min(0, intlMessage("validation.month.range"))
          .max(11, intlMessage("validation.month.range"))
          .optional(),
        partialCalendarMonth: z
          .object({ value: z.coerce.number(intlTypeError("validation.month.format")) })
          .optional(),
        partialCalendarYear: z.coerce.number(intlTypeError("validation.year.format")).optional(),
        fullAgeYear: z.coerce.number(intlTypeError("validation.year.format")).optional(),
        fullAgeMonth: z.coerce
          .number(intlTypeError("validation.month.format"))
          .min(0, intlMessage("validation.month.range"))
          .max(11, intlMessage("validation.month.range"))
          .optional(),
        fullCalendarMonth: z
          .object({ value: z.coerce.number(intlTypeError("validation.month.format")) })
          .optional(),
        fullCalendarYear: z.coerce
          .number(intlTypeError("validation.year.format"))
          .optional(),
      })
      .refine(
        ({ isEarlierOrLaterRetirementSelected, fullAgeYear }) => {
          const isAgeYearRequired = isEarlierOrLaterRetirementSelected && !fullAgeYear;
          return !isAgeYearRequired;
        },
        { message: intlMessage("validation.year.required"), path: ["fullAgeYear"] }
      )
      .superRefine(
        ({ fullAgeYear, fullAgeMonth = 0, isEarlierOrLaterRetirementSelected }, ctx) => {
          if (!isEarlierOrLaterRetirementSelected) return;
          if (!fullAgeYear) return;

          const isInvalidMax = fullAgeYear * 12 + fullAgeMonth > retirement.maxRetirementAgeInMonths;
          const isInvalidMin = fullAgeYear * 12 + fullAgeMonth < retirement.minRetirementAgeInMonths;

          const earlyRetirementYear = fullAgeYear < aowAgeInMonthsAndYears?.years;
          const isRetirementYear = fullAgeYear === aowAgeInMonthsAndYears?.years;
          const earlyRetirementMonth = fullAgeMonth < aowAgeInMonthsAndYears?.months;
          
          const isEarlyDisabilityYear = hasDisability && earlyRetirementYear;
          const isEarlyDisabilityMonth = hasDisability && isRetirementYear && earlyRetirementMonth;
          
          if (isInvalidMin || isInvalidMax) {  
            const maxYears = limits?.latestRetirementAgeYears;
            const minYears = limits?.earliestRetirementAgeYears;
            const maxMonths = limits?.latestRetirementAgeMonths;
            const minMonths = limits?.earliestRetirementAgeMonths;

            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 
              isInvalidMin
                ? intlMessageInput("planner.earlier-or-later.age-min", { years: minYears, months: minMonths })
                : intlMessageInput("planner.earlier-or-later.age-max", { years: maxYears, months: maxMonths }),
              path: ["fullAgeMonth"],
            });

            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: "",
              path: ["fullAgeYear"],
            });
            
            const minCalendarYear = limits.earliestRetirementCalendarYear;
            const maxCalendarYear = limits.latestRetirementCalendarYear;
            const minCalendarMonth = intlMessage("utils.month." + numberToMonth(limits.earliestRetirementCalendarMonth + 1));
            const maxCalendarMonth = intlMessage("utils.month." + numberToMonth(limits.latestRetirementCalendarMonth + 1));
            
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: "",
              path: ["fullCalendarMonth"],
            });

            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: isInvalidMin
                ? intlMessageInput("planner.earlier-or-later.date-min", { year: minCalendarYear, month: minCalendarMonth })
                : intlMessageInput("planner.earlier-or-later.date-max", { year: maxCalendarYear, month: maxCalendarMonth }),
              path: ["fullCalendarYear"],
            });
          }

          if (isEarlyDisabilityYear || isEarlyDisabilityMonth) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: intlMessage("validation.age-years.disability"),
              path: ["fullAgeYear"],
            });
          }
        }
      )
      .refine(
        ({ isEarlierOrLaterRetirementSelected, partialAgeYear, fullyOrPartial }) => {
          const isAgeYearRequired =
            isEarlierOrLaterRetirementSelected &&
            fullyOrPartial?.value !== EARLIER_LATER_FULLY &&
            !partialAgeYear;
          return !isAgeYearRequired;
        },
        { message: intlMessage("validation.year.required"), path: ["partialAgeYear"] }
      )
      .superRefine(
        (
          { partialAgeYear, partialAgeMonth = 0, isEarlierOrLaterRetirementSelected },
          ctx
        ) => {
          if (!isEarlierOrLaterRetirementSelected) return;
          if (!partialAgeYear) return;

          const isInvalidMax = partialAgeYear * 12 + partialAgeMonth > retirement.maxRetirementAgeInMonths;
          const isInvalidMin = partialAgeYear * 12 + partialAgeMonth < retirement.minRetirementAgeInMonths;

          const earlyRetirementYear = partialAgeYear < aowAgeInMonthsAndYears?.years;
          const isRetirementYear = partialAgeYear === aowAgeInMonthsAndYears?.years;
          const earlyRetirementMonth = partialAgeMonth < aowAgeInMonthsAndYears?.months;

          const isEarlyDisabilityYear = hasDisability && earlyRetirementYear;
          const isEarlyDisabilityMonth = hasDisability && isRetirementYear && earlyRetirementMonth;

          if (isInvalidMin || isInvalidMax) {  
            const maxYears = limits?.latestRetirementAgeYears;
            const minYears = limits?.earliestRetirementAgeYears;
            const maxMonths = limits?.latestRetirementAgeMonths;
            const minMonths = limits?.earliestRetirementAgeMonths;

            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 
              isInvalidMin
              ? intlMessageInput("planner.earlier-or-later.age-min", { years: minYears, months: minMonths.toString() })
              : intlMessageInput("planner.earlier-or-later.age-max", { years: maxYears, months: maxMonths.toString() }),
              path: ["partialAgeMonth"],
            });

            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: "",
              path: ["partialAgeYear"],
            });
            
            const minCalendarYear = limits.earliestRetirementCalendarYear;
            const maxCalendarYear = limits.latestRetirementCalendarYear;
            const minCalendarMonth = intlMessage("utils.month." + numberToMonth(limits.earliestRetirementCalendarMonth + 1));
            const maxCalendarMonth = intlMessage("utils.month." + numberToMonth(limits.latestRetirementCalendarMonth + 1));
            
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: "",
              path: ["partialCalendarMonth"],
            });

            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: isInvalidMin
                ? intlMessageInput("planner.earlier-or-later.date-min", { year: minCalendarYear, month: minCalendarMonth })
                : intlMessageInput("planner.earlier-or-later.date-max", { year: maxCalendarYear, month: maxCalendarMonth }),
              path: ["partialCalendarYear"],
            });
          }

          if (isEarlyDisabilityYear || isEarlyDisabilityMonth) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: intlMessage("validation.age-years.disability"),
              path: ["partialAgeYear"],
            });
          }
        }
      )
      .superRefine(
        (
          {
            fullAgeYear,
            fullAgeMonth = 0,
            isEarlierOrLaterRetirementSelected,
            fullyOrPartial,
            partialAgeMonth = 0,
            partialAgeYear,
          },
          ctx
        ) => {
          if (!isEarlierOrLaterRetirementSelected) return;
          if (fullyOrPartial.value === EARLIER_LATER_FULLY) return;
          if (!fullAgeYear || !partialAgeYear) return;

          if (partialAgeYear > fullAgeYear) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: intlMessage("validation.age-years.invalid"),
              path: ["partialAgeYear"],
            });
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: intlMessage("validation.age-years.invalid"),
              path: ["fullAgeYear"],
            });
          } else if (partialAgeYear === fullAgeYear) {
            if (partialAgeMonth >= fullAgeMonth) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: intlMessage("validation.age-months.invalid"),
                path: ["partialAgeMonth"],
              });
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: intlMessage("validation.age-months.invalid"),
                path: ["fullAgeMonth"],
              });
            }
          }
        }
      );

  const getExchangeOptionSchema = (limits: RetirementOptionsLimits) => {
    return z
      .object({
        isSelected: z.boolean().optional(),
        exchangeType: z
          .array(z.object({ value: z.string(), checked: z.boolean() }))
          .transform((options) => options.find((option) => option.checked)?.value)
          .optional(),
        isMaxExchange: z
          .array(z.object({ value: z.string(), checked: z.boolean() }))
          .transform((options) => options.find((option) => option.checked)?.value === "yes")
          .optional(),
        exchangeAmount: z
          .string()
          .transform((value, ctx) => {
            if (!formatNumber.test(value ?? "")) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: intlMessage("validation.amount.numeric"),
              });
              return z.NEVER;
            }
            const parsedNumber =
              locale === PortalLanguage.NL
                ? parseFloat(value.replace(/\./g, "").replace(",", "."))
                : parseFloat(value.replace(/,/g, ""));
            if (isNaN(parsedNumber)) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: intlMessage("validation.amount.numeric"),
              });
              return z.NEVER;
            }
            if (parsedNumber < 0) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: intlMessage("validation.amount.invalid"),
              });
              return z.NEVER;
            }
            return parsedNumber ?? 0;
          })
          .optional(),
      })
      .refine(
        ({ isSelected, exchangeType }) => {
          if (!isSelected) return true;
          return !!exchangeType;
        },
        {
          message: intlMessage("validation.required"),
          path: ["exchangeType"],
        }
      )
      .refine(
        (data) =>
          !(
            data.exchangeType === ExchangeType.OPPP &&
            data?.exchangeAmount &&
            data.exchangeAmount > limits?.defaultMaximumExchangeOP
          ),
        {
          message: intlMessage("validation.amount.invalid"),
          path: ["exchangeAmount"],
        }
      )
      .refine(
        (data) =>
          !(
            data.exchangeType === ExchangeType.PPOP &&
            data?.exchangeAmount &&
            data.exchangeAmount > limits?.defaultMaximumExchangePP
          ),
        {
          message: intlMessage("validation.amount.invalid"),
          path: ["exchangeAmount"],
        }
      );
  };

  const getBridgingOptionSchema = () => {
    return z
      .object({
        isSelected: z.boolean().optional(),
        isMaxExchangeAow: z
          .array(z.object({ value: z.string(), checked: z.boolean() }))
          .transform(
            (options) => options.find((option) => option.checked)?.value === UseMaximumExchangeAow.YES
          )
          .optional(),
        maxExchangeAowAmount: z.number(intlTypeError("validation.number.format")).optional(),
        bridgingAmount: z
          .string()
          .transform((value, ctx) => {
            if (!formatNumber.test(value ?? "")) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: intlMessage("validation.amount.numeric"),
              });
              return z.NEVER;
            }
            const parsedNumber =
              locale === PortalLanguage.NL
                ? parseFloat(value.replace(/\./g, "").replace(",", "."))
                : parseFloat(value.replace(/,/g, ""));
            return parsedNumber ?? 0;
          })
          .optional(),
      })
      .superRefine((data, ctx) => {
        const { bridgingAmount, isMaxExchangeAow, isSelected } = data;

        if (!isSelected || isMaxExchangeAow) return;

        if (bridgingAmount && isNaN(bridgingAmount)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: intlMessage("validation.amount.numeric"),
          });
          return z.NEVER;
        }
        if (bridgingAmount && !bridgingAmount && bridgingAmount <= 0) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: intlMessage("validation.amount.invalid"),
          });
          return z.NEVER;
        }
      })
      .refine(
        (data) => {
          const { bridgingAmount, maxExchangeAowAmount, isMaxExchangeAow, isSelected } = data;

          if (!isSelected || isMaxExchangeAow || isMaxExchangeAow === undefined) return true;

          return bridgingAmount && maxExchangeAowAmount && bridgingAmount <= maxExchangeAowAmount;
        },
        {
          message: intlMessage("validation.amount.invalid"),
          path: ["bridgingAmount"],
        }
      );
  };

  const getHLLHSchema = () =>
    z
      .object({
        isSelected: z.boolean(),
        exchangeOptions: z
          .object({ checked: z.boolean(), value: z.string() })
          .array()
          .refine(
            (values) => {
              const isAnyExchangeOptionsChecked = !!values.find((item) => item.checked);
              return isAnyExchangeOptionsChecked;
            },
            {
              message: intlMessage("validation.option.select"),
            }
          )
          .optional(),
        numberOfMonths: z.coerce
          .number(intlTypeError("validation.number.format"))
          .min(1, intlMessage("validation.months.hllh.range"))
          .max(144, intlMessage("validation.months.hllh.range"))
          .optional(),
        calendarMonth: z
          .object({ value: z.coerce.number(intlTypeError("validation.month.format")) })
          .transform((value) => value.value)
          .optional(),
        calendarYear: z.coerce
          .number(intlTypeError("validation.year.format"))
          .min(1900, "Invalid value")
          .optional(),
      })
      .refine(
        ({ exchangeOptions, isSelected }) => {
          const isValidExchangeOptions = !isSelected || exchangeOptions;
          return isValidExchangeOptions;
        },
        {
          message: intlMessage("validation.required"),
          path: ["exchangeOptions"],
        }
      )
      .refine(
        ({ isSelected, numberOfMonths }) => {
          const isValid = !isSelected || (isSelected && numberOfMonths);
          return isValid;
        },
        {
          message: intlMessage("validation.required"),
          path: ["numberOfMonths"],
        }
      );

  const getWorkingTimeOptionSchema = (limitAge: LimitsAge, retirementYear: number) => {
    return z
      .object({
        isSelected: z.boolean().optional(),
        hours: requiredHourSchema,
        ageYear: requiredAgeSchema(limitAge),
        ageMonth: requiredMonthSchema,
        calendarYear: requiredStartYearSchema(retirementYear),
        calendarMonth: z
          .object({ value: z.coerce.number(intlTypeError("validation.month.format")) })
          .transform((value) => value.value - 1)
          .optional(),
      })
      .refine((data) => !(data.isSelected && data.hours === undefined), {
        message: intlMessage("validation.required"),
        path: ["hours"],
      })
      .refine((data) => !(data.hours && data.ageYear === undefined), {
        message: intlMessage("validation.age-year.required"),
        path: ["ageYear"],
      })
      .refine(
        (data) => {
          const ageMonth = data.ageMonth ?? 0;
          const currentAge = limitAge?.currentAge;
          const retirementAge = limitAge?.retirementAge;
          if (
            (data?.ageYear === currentAge?.years && ageMonth < currentAge?.months) ||
            (data?.ageYear === retirementAge?.years && ageMonth > retirementAge?.months)
          ) {
            return false;
          }
          return true;
        },
        {
          message: intlMessage("validation.age-months.exceed"),
          path: ["ageMonth"],
        }
      );
  };

  const getEarningSalaryOptionSchema = (limitAge: LimitsAge, retirementYear: number) => {
    return z
      .object({
        isSelected: z.boolean().optional(),
        salary: requiredSalarySchema,
        ageYear: requiredAgeSchema(limitAge),
        ageMonth: requiredMonthSchema,
        calendarYear: requiredStartYearSchema(retirementYear),
        calendarMonth: z
          .object({ value: z.coerce.number(intlTypeError("validation.month.format")) })
          .transform((value) => value.value - 1)
          .optional(),
      })
      .refine((data) => !(data.isSelected && data.salary === undefined), {
        message: intlMessage("validation.required"),
        path: ["salary"],
      })
      .refine((data) => !(data.salary && data.ageYear === undefined), {
        message: intlMessage("validation.salary.required"),
        path: ["ageYear"],
      })
      .refine(
        (data) => {
          const ageMonth = data.ageMonth ?? 0;
          if (
            (data?.ageYear === limitAge?.currentAge?.years && ageMonth < limitAge?.currentAge?.months) ||
            (data?.ageYear === limitAge?.retirementAge?.years && ageMonth > limitAge?.retirementAge?.months)
          ) {
            return false;
          }
          return true;
        },
        {
          message: intlMessage("validation.age-months.exceed"),
          path: ["ageMonth"],
        }
      );
  };

  const getQuittingWorkOptionSchema = (limitAge: LimitsAge, retirementYear: number) => {
    return z
      .object({
        isSelected: z.boolean().optional(),
        ageYear: requiredAgeSchema(limitAge),
        ageMonth: requiredMonthSchema,
        calendarYear: requiredStartYearSchema(retirementYear),
        calendarMonth: z
          .object({ value: z.coerce.number(intlTypeError("validation.month.format")) })
          .transform((value) => value.value - 1)
          .optional(),
      })
      .refine((data) => !(data.isSelected && data.ageYear === undefined), {
        message: intlMessage("validation.required"),
        path: ["ageYear"],
      })
      .refine(
        (data) => {
          const ageMonth = data.ageMonth ?? 0;
          if (
            (data?.ageYear === limitAge?.currentAge?.years && ageMonth < limitAge?.currentAge?.months) ||
            (data?.ageYear === limitAge?.retirementAge?.years && ageMonth > limitAge?.retirementAge?.months)
          ) {
            return false;
          }
          return true;
        },
        {
          message: intlMessage("validation.age-months.exceed"),
          path: ["ageMonth"],
        }
      );
  };

  const getEmploymentOptionSchema = (limitAge: LimitsAge, retirementYear: number) => {
    return z
      .object({
        workingTimeOption: getWorkingTimeOptionSchema(limitAge, retirementYear).optional(),
        earningSalaryOption: getEarningSalaryOptionSchema(limitAge, retirementYear).optional(),
        quittingWorkOption: getQuittingWorkOptionSchema(limitAge, retirementYear).optional(),
      })
      .optional();
  };

  const getRetirementOptionsSchema = ({
    retirement,
    limits,
    isActive,
    hasDisability,
  }: {
    retirement: Retirement;
    limits: RetirementOptionsLimits;
    isActive: boolean;
    hasDisability: boolean;
  }) => {
    return z
      .object({
        surrenderOptions: getSurrenderOptionSchema({ limits, isActive }).optional(),
        earlierOrLaterRetirementOptions: getEarlierOrLatestOptionSchema({
          retirement,
          limits,
          hasDisability,
        }).optional(),
        exchangeOption: getExchangeOptionSchema(limits).optional(),
        bridgingOption: getBridgingOptionSchema().optional(),
        highLowLowHigh: getHLLHSchema().optional(),
      })
      .optional();
  };

  return z
    .object({
      employmentOptions: getEmploymentOptionSchema(plannerParam?.limitAge, plannerParam?.retirementYear),
      retirementOption: getRetirementOptionsSchema({
        retirement: plannerParam?.retirement,
        limits: plannerParam?.retirementOptionsLimits,
        isActive: plannerParam.isActive,
        hasDisability: plannerParam.hasDisability,
      }),
    })
    .superRefine(({ employmentOptions, retirementOption }, ctx) => {
      const { workingTimeOption, earningSalaryOption, quittingWorkOption } = employmentOptions ?? {};

      let optionSelected: EmploymentOptionKeys | undefined;
      if (workingTimeOption?.isSelected) {
        optionSelected = EmploymentOptionKeys.WorkingTimeOption;
      } else if (earningSalaryOption?.isSelected) {
        optionSelected = EmploymentOptionKeys.EarningSalaryOption;
      } else if (quittingWorkOption?.isSelected) {
        optionSelected = EmploymentOptionKeys.QuittingWorkOption;
      }

      if (!optionSelected) return;

      const ageYear = employmentOptions?.[optionSelected]?.ageYear;
      const ageMonth = employmentOptions?.[optionSelected]?.ageMonth ?? 0;
      const { fullAgeYear, fullAgeMonth = 0 } = retirementOption?.earlierOrLaterRetirementOptions ?? {};

      if (!ageYear || !fullAgeYear) return;

      if (ageYear > fullAgeYear) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: intlMessage("validation.age-years.invalid"),
          path: ["employmentOptions", optionSelected, "ageYear"],
        });
      } else if (ageYear === fullAgeYear) {
        if (ageMonth > fullAgeMonth - 1) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: intlMessage("validation.age-months.invalid"),
            path: ["employmentOptions", optionSelected, "ageMonth"],
          });
        }
      }
    });
};

export type TPlannerSchema = z.infer<ReturnType<typeof usePlannerSchema>>;
