import {
  Checkbox,
  FormField,
  FormSubmit,
  FormTextBlock,
  Input,
  SearchDropdown,
} from "@faraday-gitlab/bpfd-portal";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient, useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useMemo } from "react";
import { useForm, FieldValues, Controller } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { useAuthentication } from "../../../context/AuthenticatedContext";
import {
  TCorrespondenceAddressDetailsSchema,
  useCorrespondenceAddressDetailsSchema,
} from "../../../hooks/schemas/generalSchema";
import useCountries from "../../../hooks/useCountries";
import { useIntlMessage } from "../../../hooks/useIntlMessage";
import { Preferences } from "../../../lib/types";
import { updateUserCorrespondenceSettings } from "../../../react-query/mutations";
import { getCommunicationPreferences } from "../../../react-query/queries";
import { preferencesKeys } from "../../../react-query/query-keys";
import { HREF } from "../../../routes/routes";
import { getPreferencesOrDefault } from "../../../utils/defaultPreferences";
import { handleExpiredToken, RetryCallback } from "../../../utils/handleExpiredToken";
import Loading from "../../organisms/Loading";
import StandardFormWrapper from "../../templates/StandardFormWrapper";

interface Props {
  defaultValues: DefaultValues;
  userId: string;
  countriesOptions: { value: string; id: string; label: string }[];
  defaultCountry: { value: string; id: string; label: string } | null;
}

interface DefaultValues {
  isSameAddress: boolean;
  country: { value: string; id: string; label: string; key: string } | null;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
}

const UserCorrespondenceAddressFormChild: React.FC<Props> = ({
  defaultValues,
  userId,
  countriesOptions,
  defaultCountry,
}) => {
  const intlMessage = useIntlMessage();
  const { countries } = useCountries();

  const isSameAddressKey = "isSameAddress";
  const countryKey = "country";
  const addressLine1Key = "addressLine1";
  const addressLine2Key = "addressLine2";
  const addressLine3Key = "addressLine3";

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const {
    mutateAsync,
    isPending,
    error: postError,
    reset,
  } = useMutation({
    mutationFn: updateUserCorrespondenceSettings,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: preferencesKeys.user(userId) });
      navigate(HREF.MY_DATA_TAB_2);
    },
    onError: async (postError, data): Promise<void> => {
      handleExpiredToken(postError, mutateAsync as RetryCallback, data);
      setError(isSameAddressKey, { message: postError?.message });
    },
  });

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
    formState: { errors, isSubmitting, isDirty, isValid },
  } = useForm<TCorrespondenceAddressDetailsSchema>({
    mode: "onChange",
    resolver: zodResolver(useCorrespondenceAddressDetailsSchema()),
    defaultValues: defaultValues,
  });

  const isSameAddress = watch(isSameAddressKey);

  useEffect(() => {
    if (!isSameAddress) {
      setValue(countryKey, defaultCountry);
      return;
    } else {
      setValue(countryKey, null);
    }

    setValue(addressLine1Key, "");
    setValue(addressLine2Key, "");
    setValue(addressLine3Key, "");
  }, [isSameAddress, defaultCountry, setValue, defaultValues]);

  const onSubmit = async (data: FieldValues) => {
    const country = countries.find((country) => country.countryCode === data.country?.value);

    await mutateAsync({
      isSameAddress: data.isSameAddress,
      corCountry: country?.countryNameEN,
      corAddress1: data.addressLine1,
      corAddress2: data.addressLine2,
      corAddress3: data.addressLine3,
    } as Preferences);
    reset();
  };

  return (
    <StandardFormWrapper>
      <form onSubmit={handleSubmit((data) => onSubmit(data))}>
        <FormTextBlock heading={<FormattedMessage id="my-data.correspondence-address" />} />
        <FormField className="col-span-12" id={isSameAddressKey}>
          <Controller
            name={isSameAddressKey}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Checkbox
                id={isSameAddressKey}
                checked={value}
                onChange={onChange}
                label={<FormattedMessage id="my-data.correspondence-settings.same-address" />}
              />
            )}
          />
        </FormField>
        {!isSameAddress && (
          <>
            <FormField
              className="col-span-12 mt-10"
              id="country"
              labelText={<FormattedMessage id="utils.country" />}>
              <SearchDropdown
                name={"country"}
                items={countriesOptions}
                control={control}
                disabled={isSubmitting || isPending}
                placeholder={intlMessage("utils.country-select")}
              />
            </FormField>
            <FormField
              className="col-span-12"
              id={addressLine1Key}
              labelText={<FormattedMessage id="utils.address-line" values={{ number: "1" }} />}>
              <Input
                id={addressLine1Key}
                name={addressLine1Key}
                control={control}
                disabled={isSubmitting || isPending}
                error={!!errors.addressLine1}
                placeholder={intlMessage("my-data.correspondence-address.address-line-placeholder.1")}
              />
            </FormField>
            <FormField
              className="col-span-12"
              id={addressLine2Key}
              labelText={<FormattedMessage id="utils.address-line" values={{ number: "2" }} />}>
              <Input
                id={addressLine2Key}
                name={addressLine2Key}
                control={control}
                disabled={isSubmitting || isPending}
                error={!!errors.addressLine2}
                placeholder={intlMessage("my-data.correspondence-address.address-line-placeholder.2")}
              />
            </FormField>
            <FormField
              className="col-span-12"
              id={addressLine3Key}
              labelText={<FormattedMessage id="utils.address-line" values={{ number: "3" }} />}>
              <Input
                id={addressLine3Key}
                name={addressLine3Key}
                control={control}
                disabled={isSubmitting || isPending}
                error={!!errors.addressLine3}
              />
            </FormField>
          </>
        )}
        <FormField id="country">
          <FormSubmit
            buttons={[
              {
                size: "md",
                ariaLabel: "button",
                text: <FormattedMessage id="utils.save" />,
                type: "submit",
                disabled: !isDirty || isSubmitting || !isValid,
              },
              {
                size: "md",
                ariaLabel: "link",
                onClick: () => {
                  navigate(-1);
                },
                text: <FormattedMessage id="utils.cancel" />,
              },
            ]}
            errorMessage={postError?.message}
          />
        </FormField>
      </form>
    </StandardFormWrapper>
  );
};

const UserCorrespondenceAddressForm = () => {
  const data = useAuthentication();
  const { data: preferences, isLoading: comIsLoading } = useQuery({
    queryKey: preferencesKeys.user(data?.user?.id),
    queryFn: getCommunicationPreferences,
    select: (data) => getPreferencesOrDefault(data.preferences[0] as Preferences),
    enabled: !!data?.user?.id,
  });

  const { countriesByUserLng, countryInUserLngByEnglishName } = useCountries();
  const countriesOptions = useMemo(
    () =>
      countriesByUserLng.map((item) => ({
        value: item.countryCode,
        id: item.countryCode,
        label: item.countryName,
        key: item.countryCode,
      })),
    [countriesByUserLng]
  );

  const userCountry = countryInUserLngByEnglishName(preferences?.corCountry ?? "")?.countryName;

  const defaultValues: DefaultValues = {
    isSameAddress: preferences?.isSameAddress ?? true,
    country:
      preferences?.corCountry !== undefined
        ? (countriesOptions.find((country) => country.label === userCountry) ?? null)
        : null,
    addressLine1: preferences?.corAddress1 ?? "",
    addressLine2: preferences?.corAddress2 ?? "",
    addressLine3: preferences?.corAddress3 ?? "",
  };

  const theNetherlands = countryInUserLngByEnglishName("The Netherlands") ?? null;
  const defaultCountry = defaultValues?.country ?? {
    value: theNetherlands?.countryCode ?? "",
    id: theNetherlands?.countryCode ?? "",
    label: theNetherlands?.countryName ?? "",
    key: theNetherlands?.countryCode ?? "",
  };

  if (data?.isLoading || preferences === undefined || comIsLoading) {
    return <Loading />;
  }

  return (
    <UserCorrespondenceAddressFormChild
      defaultValues={defaultValues}
      userId={data?.user?.id ?? ""}
      countriesOptions={countriesOptions}
      defaultCountry={defaultCountry}
    />
  );
};

export default UserCorrespondenceAddressForm;
