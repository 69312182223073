import { FormattedMessage } from "react-intl";
import { Gender } from "../lib/enum";
import { DropdownItem } from "@faraday-gitlab/bpfd-portal";

const useGenderOption = (): DropdownItem[] => {
  return Object.entries(Gender).map(([_, value]) => ({
    id: value,
    label: <FormattedMessage id={`utils.gender.${value}`} />,
    value,
  }));
};

export default useGenderOption;
