import { ReactNode } from "react";

interface Props {
  children: ReactNode;
}

const PlannerCell: React.FC<Props> = ({ children }) => {
  return <div className="w-full col-span-12 grid-cols-12 grid gap-x-5">{children}</div>;
};

export default PlannerCell;
