import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { FormattedMessage, IntlProvider } from "react-intl";
import { pageLinks, plannerPageLink, personalButtons, HREF } from "./routes/routes";
import { Header, Footer, Button, LabeledIcon } from "@faraday-gitlab/bpfd-portal";
import { linkedInUrl } from "./utils/socialMedia";
import { useAuthentication } from "./context/AuthenticatedContext";
import { APILanguage, PortalLanguage } from "./utils/languageLoader";
import { PageLink } from "./lib/types";
import { messages } from "./lib/messages";
import PageBackground from "./components/templates/PageBackground";

// warning here is a false positive, this is a valid import as it comes from Faraday
import "../node_modules/@faraday-gitlab/bpfd-portal/dist/styles/main.min.css"; // NOSONAR

const App = () => {
  const { user, toggles } = useAuthentication() || {};
  const navigate = useNavigate();
  const location = useLocation();
  const [publicMenu, setPublicMenu] = useState<PageLink[]>([]);
  const [isNavOpen, setIsNavOpen] = useState<boolean>(false);
  const [isPlannerDisabled, setIsPlannerDisabled] = useState<boolean>();

  const language = user?.language === APILanguage.EN ? PortalLanguage.EN : PortalLanguage.NL;
  const isPublicPage = !!publicMenu.find((page) => page.href === location.pathname);
  const isActive = !!publicMenu.find((page) => page.href === location.pathname && page.active);
  const hasActivePublicPage = !!publicMenu.find((page) => page.active);

  const handleActivePublicPage = useCallback(
    (isActive: boolean) => {
      const newPublicMenu = publicMenu.map((page) => {
        return {
          ...page,
          active: isActive ? page.href === location.pathname : false,
        };
      });
      setPublicMenu(newPublicMenu);
    },
    [publicMenu, location.pathname]
  );

  // add planner page to public menu if planner page is enabled
  if (user && toggles?.enablePlanner && !publicMenu.find((p) => p.href === HREF.PLANNER)) {
    const newPublicMenu = [...pageLinks];
    newPublicMenu.splice(1, 0, plannerPageLink);
    setPublicMenu(newPublicMenu);
    setIsPlannerDisabled(false);
  }

  // handle first load of menu and planner page URL redirect
  useEffect(() => {
    const isDataLoaded = user && !publicMenu.length;
    if (isDataLoaded) {
      setPublicMenu(pageLinks);
    }

    if (location.pathname === HREF.PLANNER && !toggles?.enablePlanner && isPlannerDisabled) {
      navigate(HREF.NOTFOUND, { replace: true });
    }
  }, [isPlannerDisabled, location.pathname, navigate, publicMenu.length, toggles, user]);

  // handle active page in public menu
  useEffect(() => {
    if (!isPublicPage && hasActivePublicPage) {
      handleActivePublicPage(false);
    }

    if (isPublicPage && !isActive && publicMenu.length) {
      handleActivePublicPage(true);
      setIsNavOpen(false);
    }
  }, [hasActivePublicPage, isPublicPage, location.pathname, publicMenu, handleActivePublicPage, isActive]);

  const handleNavClick = () => {
    setIsNavOpen(!isNavOpen);
  };

  const handleClickLogo = () => {
    navigate(HREF.HOME);
    setIsNavOpen(false);
  };

  const handlePersonalButtonClick = (index: string) => {
    navigate(personalButtons[parseInt(index) - 1].href);
    setIsNavOpen(false);
  };

  return (
    <div className="notranslate">
      <IntlProvider locale={language ?? PortalLanguage.NL} messages={messages[language ?? PortalLanguage.NL]}>
        <PageBackground>
          <Header
            clickLogo={handleClickLogo}
            headerButtonHandler={handlePersonalButtonClick}
            headerButtonsData={personalButtons}
            links={publicMenu}
            isNavOpen={isNavOpen}
            onNavClick={handleNavClick}
          />
          <Outlet />
          <Footer
            buttonCTA={
              <Button size="sm" text={<FormattedMessage id="app.to-contact" />} href={HREF.CONTACT} />
            }
            middleContent={
              <>
                <LabeledIcon icon="phoneNavigation" text="0800-1972" />
                <LabeledIcon icon="emailNavigation" text="deelnemer@pensioenfondsdetailhandel.nl" />
              </>
            }
            rightContent={
              <LabeledIcon
                circleBackground
                clickableIcon
                onClick={() => window.open(linkedInUrl, "_blank")} //external link so no react-router
                icon="linkedIn"
                text={
                  <Link to={linkedInUrl} target="_blank">
                    <FormattedMessage id="app.social-media" />
                  </Link>
                }
              />
            }
          />
        </PageBackground>
      </IntlProvider>
    </div>
  );
};

export default App;
