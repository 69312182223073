import { FormField, FormImportantMessage, FormTextBlock, Input } from "@faraday-gitlab/bpfd-portal";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";
import { usePlanner } from "../../../context/PlannerContext";
import { ageToCalendarCalculation, calendarToAgeCalculation } from "../../../utils/ageCalendarConverter";
import { svbLink, toeslagenLink } from "../../../utils/pensionLinks";
import PlannerMonthDropdown from "../../atoms/PlannerMonthDropdown";
import PlannerCell from "../../atoms/PlannerCell";
import PlannerCellHeader from "../../atoms/PlannerCellHeader";

const SurrenderOption = () => {
  const intl = useIntl();
  const planner = usePlanner()?.planner;
  const { control, watch, getValues, setValue, trigger } = useFormContext();

  const ageYearKey = "retirementOption.surrenderOptions.ageYear";
  const ageMonthKey = "retirementOption.surrenderOptions.ageMonth";
  const calendarYearKey = "retirementOption.surrenderOptions.calendarYear";
  const calendarMonthKey = "retirementOption.surrenderOptions.calendarMonth";

  const [watchedAgeYear, watchedAgeMonth, watchedCalendarYear, watchedCalendarMonth] = watch([
    ageYearKey,
    ageMonthKey,
    calendarYearKey,
    calendarMonthKey,
  ]);

  const dateOfBirth = planner?.person?.dateOfBirth ?? "";

  useEffect(() => {
    ageToCalendarCalculation(
      new Date(dateOfBirth),
      { year: Number(watchedAgeYear), month: Number(watchedAgeMonth ?? 0) },
      { yearKey: calendarYearKey, monthKey: calendarMonthKey },
      { getValues, setValue }
    );
  }, [watchedAgeYear, watchedAgeMonth, dateOfBirth, getValues, setValue]);

  useEffect(() => {
    calendarToAgeCalculation(
      new Date(dateOfBirth),
      { year: Number(watchedCalendarYear), month: Number(watchedCalendarMonth?.value) },
      { yearKey: ageYearKey, monthKey: ageMonthKey },
      { getValues, setValue }
    );
  }, [watchedCalendarYear, watchedCalendarMonth, dateOfBirth, getValues, setValue]);

  useEffect(() => {
    if (watchedAgeYear || watchedAgeMonth) {
      trigger([watchedAgeYear, watchedAgeMonth]);
    }
  }, [watchedAgeYear, watchedAgeMonth, trigger]);

  return (
    <>
      <FormTextBlock
        heading={<FormattedMessage id="planner.surrender.title" />}
        body={
          <FormattedMessage
            id="planner.surrender.body-text"
            values={{
              link1: (
                <Link to={toeslagenLink} target="_blank">
                  <FormattedMessage id="planner.link.toeslagen" />
                </Link>
              ),
              link2: (
                <Link to={svbLink} target="_blank">
                  <FormattedMessage id="planner.link.svb" />
                </Link>
              ),
            }}
          />
        }
        className="col-span-12"
      />
      {!watchedAgeYear && (
        <FormImportantMessage
          body={<FormattedMessage id="planner.surrender.warning-text" />}
          className="col-span-12 mb-15 border-yellow-light-pastel"
        />
      )}
      <Fragment key=".1">
        <PlannerCell>
          <PlannerCellHeader heading={<FormattedMessage id="planner.surrender.full-pension-from" />} />
          <FormField
            id="ageYear"
            labelText={<FormattedMessage id="utils.year-capital" />}
            className="col-span-6">
            <Input
              id="ageYear"
              name={ageYearKey}
              control={control}
              placeholder={intl.formatMessage({ id: "utils.example" }, { value: "65" })}
            />
          </FormField>
          <FormField
            id="ageMonth"
            className="col-span-6"
            labelText={<FormattedMessage id="utils.month-capital" />}>
            <Input
              id="ageMonth"
              name={ageMonthKey}
              control={control}
              placeholder={intl.formatMessage(
                { id: "utils.example" },
                { value: `5 ${intl.formatMessage({ id: "utils.months" })}` }
              )}
            />
          </FormField>
        </PlannerCell>
        <PlannerCell>
          <PlannerCellHeader heading={<FormattedMessage id="utils.per" />} />
          <PlannerMonthDropdown
            inputName={calendarMonthKey}
            control={control}
            placeholder={intl.formatMessage({ id: "planner.select-month" })}
          />
          <FormField
            className="col-span-6"
            id="calendarYear"
            labelText={<FormattedMessage id="utils.year-capital" />}>
            <Input
              name={calendarYearKey}
              placeholder={intl.formatMessage({ id: "utils.example" }, { value: "65" })}
              control={control}
            />
          </FormField>
        </PlannerCell>
      </Fragment>
    </>
  );
};

export default SurrenderOption;
