import { z } from "zod";
import { useBankAccountSchema } from "./bankAccountSchema";

const option = z.object({ value: z.string(), checked: z.boolean() });

export const usePlannerAdditionalQuestSchema = () => {
  const isBankNumberOnly = true;
  const bankAccountSchema = useBankAccountSchema(isBankNumberOnly);
  return z.object({
    bankAccount: bankAccountSchema(),
    havingPartnerOptions: z.array(option),
    taxReductionOptions: z.array(option),
    disabledOptions: z.array(option),
  });
};

export type TPlannerAdditionalQuestSchema = z.infer<ReturnType<typeof usePlannerAdditionalQuestSchema>>;
