import { z } from "zod";
import { useBankAccountSchema } from "./bankAccountSchema";

const option = z.object({ value: z.string(), checked: z.boolean() });

export const useRetirementChoiceSchema = () => {
  const isBankNumberOnly = true;
  const bankAccountSchema = useBankAccountSchema(isBankNumberOnly);

  return z.object({
    bankAccount: bankAccountSchema(),
    havingPartnerOptions: z.array(option),
    taxReductionOptions: z.array(option),
    disabledOptions: z.array(option).optional(),
  });
};

export type TRetirementChoiceSchema = z.infer<ReturnType<typeof useRetirementChoiceSchema>>;
