import { UNAUTHORIZED_ERROR_CODE, refreshAuthToken } from "./token";

export type RetryCallback = (data?: unknown) => Promise<void>;

export async function handleExpiredToken(
  postError: Error,
  callback: RetryCallback,
  data?: unknown
): Promise<void> {
  if (postError?.message === UNAUTHORIZED_ERROR_CODE) {
    return await refreshAuthToken().then((response) => {
      if (response?.accessToken && data) {
        return callback(data);
      }
      if (response?.accessToken) {
        return callback();
      }
    });
  }
}
