import { Accordion, AccordionItem, Checkbox } from "@faraday-gitlab/bpfd-portal";
import { useEffect, useMemo, useState } from "react";
import { Controller, FieldValues, useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { usePlanner } from "../../../context/PlannerContext";
import { SituationCategory } from "../../../lib/enum";
import { Retirement, Situation } from "../../../lib/types";
import { hasSurrender } from "../../../utils/hasSurrender";
import { isEmptyObject } from "../../../utils/objectUtils";
import { getDateByTotalMonths } from "../../../utils/plannerCalendar";
import AowBridgingOption from "../../molecules/plannerRetirementOptions/AowBridgingOption";
import EarlierOrLaterRetirementOption from "../../molecules/plannerRetirementOptions/EarlierOrLaterRetirementOption";
import ExchangeOPPPOption from "../../molecules/plannerRetirementOptions/ExchangeOPPPOption";
import HighLowLowHighOption from "../../molecules/plannerRetirementOptions/HighLowLowHighOption";
import SurrenderOption from "../../molecules/plannerRetirementOptions/SurrenderOption";

const isLumSumOPSelected = false; // Hardcode temporarily cause Lum Sum is not implemented yet but require to display HLLH option

const isAllowExchangeOption = (retirement: Retirement | undefined, hasSurrender: boolean) => {
  if (isEmptyObject(retirement)) return;

  const isAllowedExchange =
    retirement?.providerOptions?.isExchangeOPPP || retirement?.providerOptions?.isExchangePPOP;
  const defaultMaxExchangeOP = retirement?.exchange?.maxExchangeOP ?? 0;
  const defaultMaxExchangePP = retirement?.exchange?.maxExchangePP ?? 0;

  return !hasSurrender && isAllowedExchange && (defaultMaxExchangeOP > 0 || defaultMaxExchangePP > 0);
};

const isAllowBridgingOption = (
  participationType: string | undefined,
  isAllowExchangeAow: boolean | undefined,
  situations: Situation[] | undefined,
  dateOfBirth: string | undefined,
  retirementDate: Date,
  hasSurrender: boolean
) => {
  if (!dateOfBirth) return false;
  const aowAgeInMonths = situations?.find((item) => item.category === SituationCategory.AOW)?.ageInMonths;
  const aowAgeInMonthsDefault =
    situations?.find((item) => item.category === SituationCategory.DEFAULT)?.ageInMonths ?? 0;
  const aowDate = getDateByTotalMonths(new Date(dateOfBirth), aowAgeInMonths ?? aowAgeInMonthsDefault);
  const hasAow = retirementDate < aowDate || participationType === "OP65";

  return hasAow && !hasSurrender && isAllowExchangeAow && !isLumSumOPSelected;
};

const getSelectedRetirementDate = (retirementInfo: FieldValues) => {
  const partialCalendarYear = parseInt(retirementInfo?.partialCalendarYear ?? 0);
  const partialCalendarMonth = parseInt(retirementInfo?.partialCalendarMonth?.value ?? 1);
  const fullCalendarYear = parseInt(retirementInfo?.fullCalendarYear ?? 0);
  const fullCalendarMonth = parseInt(retirementInfo?.fullCalendarMonth?.value ?? 1);
  const partlyRetirementDate = new Date(partialCalendarYear, partialCalendarMonth - 1, 1);
  const fullyRetirementDate = new Date(fullCalendarYear, fullCalendarMonth - 1, 1);

  return partialCalendarYear > 0 && fullyRetirementDate > partlyRetirementDate
    ? partlyRetirementDate
    : fullyRetirementDate;
};

interface Props {
  isRetrieved: boolean;
}

const PlannerRetirementOptions = ({ isRetrieved }: Props) => {
  const { situations, retirement, participation, person } = usePlanner()?.planner ?? {};
  const { control, watch, setValue, resetField } = useFormContext();

  const isRefrainFromSurrenderKey = "retirementOption.surrenderOptions.isRefrainFromSurrenderSelected";
  const isSurrenderKey = "retirementOption.surrenderOptions.isSurrenderSelected";
  const surrenderOptionsKey = "retirementOption.surrenderOptions";
  const isEarlierOrLaterRetirementSelectedKey =
    "retirementOption.earlierOrLaterRetirementOptions.isEarlierOrLaterRetirementSelected";
  const earlierOrLaterRetirementKey = "retirementOption.earlierOrLaterRetirementOptions";
  const isHighLowLowHighKey = "retirementOption.highLowLowHigh.isSelected";
  const highLowLowHighKey = "retirementOption.highLowLowHigh";
  const isExchangeSelectedKey = "retirementOption.exchangeOption.isSelected";
  const exchangeOptionKey = "retirementOption.exchangeOption";
  const isAowBridgingSelectedKey = "retirementOption.bridgingOption.isSelected";
  const aowBridgingOptionKey = "retirementOption.bridgingOption";

  const retirementChanges = watch(earlierOrLaterRetirementKey);
  const partialCalendarYear = watch("retirementOption.earlierOrLaterRetirementOptions.partialCalendarYear");
  const partialCalendarMonth = watch("retirementOption.earlierOrLaterRetirementOptions.partialCalendarMonth");
  const fullCalendarYear = watch("retirementOption.earlierOrLaterRetirementOptions.fullCalendarYear");
  const fullCalendarMonth = watch("retirementOption.earlierOrLaterRetirementOptions.fullCalendarMonth");

  const providerOptions = retirement?.providerOptions;
  const retirementScheme = Number(retirement?.maxDefaultRetirementAgeInMonths) / 12;
  const hasSurrenderOption = hasSurrender(situations);
  const isDefaultExchange = retirement?.exchange?.isSelected;
  const showSurrender = !watch(isRefrainFromSurrenderKey);
  const showHLLH = !isLumSumOPSelected && (providerOptions?.isHighLow || providerOptions?.isLowHigh);

  const defaultRetirementDate = useMemo(() => {
    return new Date(participation?.defaultRetirementDate ?? 0);
  }, [participation?.defaultRetirementDate]);

  const showRegularOptions = !hasSurrenderOption || (hasSurrenderOption && watch(isRefrainFromSurrenderKey));
  const [retirementDate, setRetirementDate] = useState(defaultRetirementDate);
  const isSurrendered = hasSurrenderOption && !watch(isRefrainFromSurrenderKey);
  const hasExchangeOption = isAllowExchangeOption(retirement, isSurrendered);
  const [showBridgingOption, setShowBridgingOption] = useState(() =>
    isAllowBridgingOption(
      participation?.type,
      retirement?.providerOptions?.isBridging,
      situations,
      person?.dateOfBirth,
      retirementDate,
      isSurrendered
    )
  );

  const resetAowBridging = () => {
    setValue(isAowBridgingSelectedKey, false);
    resetField(aowBridgingOptionKey, {
      defaultValue: {
        isSelected: false,
        maxExchangeAowAmount: retirement?.maxExchangeBridging ?? 0,
      },
    });
  };

  useEffect(() => {
    isDefaultExchange && setValue(isExchangeSelectedKey, true);
  }, [isDefaultExchange, setValue]);

  useEffect(() => {
    if (!partialCalendarYear && !partialCalendarMonth && !fullCalendarYear && !fullCalendarMonth) return;
    setRetirementDate(getSelectedRetirementDate(retirementChanges));
  }, [retirementChanges, partialCalendarYear, partialCalendarMonth, fullCalendarYear, fullCalendarMonth]);

  useEffect(() => {
    setShowBridgingOption(
      isAllowBridgingOption(
        participation?.type,
        retirement?.providerOptions?.isBridging,
        situations,
        person?.dateOfBirth,
        retirementDate,
        isSurrendered
      )
    );
  }, [
    participation?.type,
    retirement?.providerOptions?.isBridging,
    situations,
    person?.dateOfBirth,
    retirementDate,
    isSurrendered,
  ]);

  const surrenderOptions = hasSurrenderOption
    ? [
        <Controller
          control={control}
          key="refrain_from_surrender"
          name={isRefrainFromSurrenderKey}
          render={({ field: { onChange, value } }) => (
            <Checkbox
              id="refrain_from_surrender"
              checked={value}
              onChange={(e) => {
                const isChecked = e.target.checked;
                if (isChecked) {
                  setValue(isSurrenderKey, false, {
                    shouldValidate: true,
                  });
                  setValue(isEarlierOrLaterRetirementSelectedKey, false, {
                    shouldValidate: true,
                  });
                  resetField(surrenderOptionsKey);
                }
                onChange(isChecked);
              }}
              label={<FormattedMessage id="planner.refrain-from-surrender.title" />}
              errorSpacing={false}
            />
          )}
        />,
        ...(showSurrender
          ? [
              <Controller
                control={control}
                key="surrender"
                name={isSurrenderKey}
                render={({ field: { onChange, value } }) => (
                  <AccordionItem
                    id="surrender"
                    variant="checkbox"
                    isChecked={value}
                    onCheckedChange={(isChecked) => {
                      if (isChecked) {
                        setValue(isRefrainFromSurrenderKey, false);
                      } else {
                        resetField(surrenderOptionsKey);
                      }
                      onChange(isChecked);
                    }}
                    title={
                      <FormattedMessage
                        id={"planner.surrender.title-with-scheme"}
                        values={{ scheme: retirementScheme ?? 0 }}
                      />
                    }
                    content={<SurrenderOption />}
                  />
                )}
              />,
            ]
          : []),
      ]
    : [];
  const earlierLaterOption = [
    <Controller
      control={control}
      key="earlier_or_later_retirement"
      name={isEarlierOrLaterRetirementSelectedKey}
      render={({ field: { onChange, value } }) => (
        <AccordionItem
          variant="checkbox"
          key="earlier_or_later_retirement"
          id="earlier_or_later_retirement"
          title={
            <FormattedMessage
              id="planner.earlier-or-later.title-with-scheme"
              values={{ scheme: retirementScheme ?? 0 }}
            />
          }
          content={<EarlierOrLaterRetirementOption />}
          isChecked={value}
          onCheckedChange={(isChecked) => {
            if (!isChecked) {
              setRetirementDate(defaultRetirementDate);
            }
            if (isChecked) {
              resetField(earlierOrLaterRetirementKey);
            }
            resetAowBridging();
            onChange(isChecked);
          }}
        />
      )}
    />,
  ];
  const HLLHOption = showHLLH
    ? [
        <Controller
          control={control}
          key="highlow_lowhigh"
          name={isHighLowLowHighKey}
          render={({ field: { onChange, value } }) => (
            <AccordionItem
              id="highlow_lowhigh"
              key="highlow_lowhigh"
              variant="checkbox"
              title={<FormattedMessage id="planner.exchange-overtime.heading-text" />}
              content={<HighLowLowHighOption />}
              isChecked={value}
              onCheckedChange={(isChecked) => {
                if (isChecked) {
                  resetField(highLowLowHighKey);
                }
                resetAowBridging();
                onChange(isChecked);
              }}
            />
          )}
        />,
      ]
    : [];
  const exchangeOption = hasExchangeOption
    ? [
        <Controller
          control={control}
          key={isExchangeSelectedKey}
          name={isExchangeSelectedKey}
          render={({ field: { onChange, value } }) => (
            <AccordionItem
              id={exchangeOptionKey}
              key="52"
              variant="checkbox"
              content={<ExchangeOPPPOption />}
              title={<FormattedMessage id={"planner.exchange.title"} />}
              isChecked={value}
              onCheckedChange={(isChecked) => {
                if (isChecked) {
                  resetField(exchangeOptionKey);
                }
                resetAowBridging();
                onChange(isChecked);
              }}
            />
          )}
        />,
      ]
    : [];
  const aowBridgingOption = showBridgingOption
    ? [
        <Controller
          control={control}
          key={isAowBridgingSelectedKey}
          name={isAowBridgingSelectedKey}
          render={({ field: { onChange, value } }) => (
            <AccordionItem
              id={aowBridgingOptionKey}
              key="bridging"
              variant="checkbox"
              content={<AowBridgingOption isRetrieved={isRetrieved} />}
              title={<FormattedMessage id={"planner.bridging.title"} />}
              isChecked={value}
              onCheckedChange={(isChecked) => {
                if (isChecked) {
                  resetField(aowBridgingOptionKey);
                } else {
                  resetAowBridging();
                }
                onChange(isChecked);
              }}
            />
          )}
        />,
      ]
    : [];
  const regularOptions = showRegularOptions
    ? [...earlierLaterOption, ...exchangeOption, ...aowBridgingOption, ...HLLHOption]
    : [];

  return (
    <Accordion
      accordionItems={[...surrenderOptions, ...regularOptions]}
      variant="checkbox"
      keyExtractor={(item) => item.key}
    />
  );
};

export default PlannerRetirementOptions;
