import { ReactNode, createContext, useContext, useMemo } from "react";
import { QueryObserverResult, RefetchOptions, useQuery } from "@tanstack/react-query";
import { getToggles, getUser } from "../react-query/queries";
import { Person, Toggles } from "../lib/types";
import { useQueryTokenRefresh } from "../hooks/useQueryTokenRefresh";
import { togglesKeys, userKeys } from "../react-query/query-keys";

interface AuthenticatedContextProps {
  user: Person | undefined;
  toggles: Toggles | undefined;
  isLoading: boolean;
  isUserPending: boolean;
  refetchToggles: (options?: RefetchOptions) => Promise<QueryObserverResult<Toggles, Error>>;
  refetchUser: (options?: RefetchOptions) => Promise<QueryObserverResult<Person, Error>>;
}

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthenticatedContext = createContext<AuthenticatedContextProps | undefined>(undefined);

export const AuthenticatedProvider = ({ children }: AuthProviderProps) => {
  const {
    data: user,
    error: errorUser,
    isLoading: isUserLoading,
    isPending: isUserPending,
    refetch: refetchUser,
  } = useQuery({
    queryKey: userKeys.all,
    queryFn: getUser,
    select: (data) => data.person as Person,
  });

  const {
    data: toggles,
    error: errorToggles,
    isLoading: isTogglesLoading,
    refetch: refetchToggles,
  } = useQuery({
    queryKey: togglesKeys.all,
    queryFn: getToggles,
    select: (data) => data.toggles as Toggles,
  });

  useQueryTokenRefresh(errorUser, refetchUser);
  useQueryTokenRefresh(errorToggles, refetchToggles);

  const isLoading = isUserLoading || isTogglesLoading;

  const contextValue = useMemo(
    () => ({ user, toggles, isLoading, refetchToggles, refetchUser, isUserPending }),
    [user, toggles, isLoading, refetchToggles, refetchUser, isUserPending]
  );

  return <AuthenticatedContext.Provider value={contextValue}>{children}</AuthenticatedContext.Provider>;
};

export const useAuthentication = (): AuthenticatedContextProps | undefined => {
  const context = useContext(AuthenticatedContext);
  return context;
};
