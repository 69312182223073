export const userKeys = {
  all: ["user"],
};

export const togglesKeys = {
  all: ["toggles"],
};

export const pensionsKeys = {
  all: ["pensions"],
};

export const plannersKeys = {
  all: ["planners"],
};

export const survivorsKeys = {
  all: ["survivors"],
};

export const choicesKeys = {
  all: ["choices"],
};

export const preferencesKeys = {
  all: ["preferences"],
  user: (id?: string) => [...preferencesKeys.all, id] as const,
};

export const validateUserKeys = {
  all: ["validate-user"],
};

export const warningsKeys = {
  all: ["warnings"],
};

export const casesKeys = {
  all: ["cases"],
};

export const divorceKeys = {
  all: ["divorce"],
};

export const providersKeys = {
  all: ["providers"],
};

export const valuetransferKeys = {
  all: ["valuetransfer"],
};

export const documentsKeys = {
  all: ["documents"],
};

export const countriesKeys = {
  all: ["countries"],
};

export const retirementChoiceKeys = {
  all: ["retirementChoice"],
};
