import { Person } from "../../../lib/types";
import MyDataPaymentInformation from "../../molecules/myData/MyDataPaymentInformation";
import FullWidthGridColumn from "../../templates/grid/FullWidthGridColumn";

interface Props {
  user: Person;
}

const MyDataThirdTab: React.FC<Props> = ({ user }) => {
  return (
    <FullWidthGridColumn>
      <MyDataPaymentInformation user={user} />
    </FullWidthGridColumn>
  );
};

export default MyDataThirdTab;
