import { Navigate, createBrowserRouter } from "react-router-dom";
import App from "../App";
import { UserContactDetailsForm } from "../components/forms/myData/UserContactDetailsForm";
import UserCorrespondenceAddressDetailsForm from "../components/forms/myData/UserCorrespondenceAddressForm";
import UserCorrespondenceSettingsForm from "../components/forms/myData/UserCorrespondenceSettingsForm";
import { UserDataForm } from "../components/forms/myData/UserDataForm";
import UserPaymentDetails from "../components/forms/myData/UserPaymentInformationForm";
import PartnerLogin from "../components/organisms/planner/acceptancePopup/PartnerLogin";
import Actions from "../components/pages/Actions";
import ValueTransferPage from "../components/pages/actions/ValueTransferPage";
import Callback from "../components/pages/Callback";
import Contact from "../components/pages/Contact";
import Documents from "../components/pages/Documents";
import Homepage from "../components/pages/Homepage";
import Login from "../components/pages/Login";
import LoginWrapper from "../components/pages/LoginWrapper";
import { Logout } from "../components/pages/Logout";
import MyData from "../components/pages/MyData";
import NotFoundPage from "../components/pages/NotFoundPage";
import PartnerDetail from "../components/pages/PartnerDetail";
import PassingAway from "../components/pages/PassingAway";
import Planner from "../components/pages/Planner";
import RetirementChoicePage from "../components/pages/RetirementChoices";
import SubmitDivorce from "../components/pages/SubmitDivorce";
import { isLoggedIn } from "../utils/loginHandler";
import { LoggedInRoute, LoggedOutRoute } from "./ProtectedRoute";
import { HREF } from "./routes";
import ValueTransferApprovalPage from "../components/pages/actions/ValueTransferApprovalPage";

export const noSlashPath = (path: string) => {
  return path.substring(1);
};

const routes = [
  {
    path: HREF.HOME,
    element: (
      <LoggedInRoute>
        <App />
      </LoggedInRoute>
    ),
    errorElement: <NotFoundPage />,
    children: [
      {
        path: HREF.EMPTY,
        element: <Homepage />,
      },
      {
        path: HREF.PLANNER,
        element: <Planner />,
      },
      {
        path: noSlashPath(HREF.ACTIONS),
        element: <Actions />,
      },
      {
        path: noSlashPath(HREF.DOCUMENTS),
        element: <Documents />,
      },
      {
        path: noSlashPath(HREF.CONTACT),
        element: <Contact />,
      },
      {
        path: noSlashPath(HREF.MY_DATA),
        element: <MyData />,
      },
      {
        path: noSlashPath(HREF.CONTACT_DETAILS_FORM),
        element: <UserContactDetailsForm />,
      },
      {
        path: noSlashPath(HREF.USER_DATA_FORM),
        element: <UserDataForm />,
      },
      {
        path: noSlashPath(HREF.USER_CORRESPONDENCE_SETTINGS_FORM),
        element: <UserCorrespondenceSettingsForm />,
      },
      {
        path: noSlashPath(HREF.USER_CORRESPONDENCE_ADDRESS_FORM),
        element: <UserCorrespondenceAddressDetailsForm />,
      },
      {
        path: noSlashPath(HREF.PAYMENT_DETAILS),
        element: <UserPaymentDetails />,
      },
      {
        path: HREF.PASSING_AWAY,
        element: <PassingAway />,
      },
      {
        path: HREF.RETIREMENT_CHOICE,
        element: <RetirementChoicePage />,
      },
      { path: HREF.VALUE_TRANSFER, element: <ValueTransferPage /> },
      { path: HREF.VALUE_TRANSFER_APPROVAL, element: <ValueTransferApprovalPage /> },
      {
        path: noSlashPath(HREF.PARTNER_DETAILS_FORM),
        element: <PartnerDetail />,
      },
      { path: HREF.SUBMIT_DIVORCE, element: <SubmitDivorce /> },
    ],
  },
  {
    path: noSlashPath(HREF.LOGIN),
    element: (
      <LoggedOutRoute>
        <LoginWrapper>
          <Login />
        </LoginWrapper>
      </LoggedOutRoute>
    ),
  },
  {
    path: noSlashPath(HREF.CALLBACK),
    element: (
      <LoggedOutRoute>
        <Callback />
      </LoggedOutRoute>
    ),
  },
  {
    path: noSlashPath(HREF.CONTACT_SUPPORT),
    element: (
      <LoggedOutRoute>
        <LoginWrapper>
          <Contact />
        </LoginWrapper>
      </LoggedOutRoute>
    ),
  },
  {
    path: noSlashPath(HREF.LOGOUT),
    element: (
      <LoggedInRoute>
        <Logout />
      </LoggedInRoute>
    ),
  },
  {
    path: noSlashPath(HREF.NOTFOUND),
    element: (
      <LoggedInRoute>
        <NotFoundPage />
      </LoggedInRoute>
    ),
  },
  {
    path: noSlashPath(HREF.LOGIN_PARTNER),
    element: (
      <LoginWrapper>
        <PartnerLogin />
      </LoginWrapper>
    ),
  },
  {
    path: noSlashPath(HREF.CONTACT_SUPPORT_PARTNER),
    element: (
      <LoginWrapper>
        <Contact isPartner />
      </LoginWrapper>
    ),
  },

  {
    path: "*",
    element: <Navigate to={!isLoggedIn() ? HREF.LOGIN : HREF.NOTFOUND} replace />,
  },
];

export const router = createBrowserRouter(routes, {
  future: {
    v7_fetcherPersist: true,
    v7_normalizeFormMethod: true,
    v7_partialHydration: true,
    v7_relativeSplatPath: true,
    v7_skipActionErrorRevalidation: true,
  },
});
