import { PageLayout } from "@faraday-gitlab/bpfd-portal";
import React, { ReactNode } from "react";

interface Props {
  children: ReactNode;
}

const PageBackground: React.FC<Props> = ({ children }) => {
  return (
    <PageLayout>
      <div className="bg-gradient-mint-blue-light flex flex-col min-h-screen">{children}</div>
    </PageLayout>
  );
};

export default PageBackground;
