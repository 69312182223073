import { Button, GenericTextBlock, Popup } from "@faraday-gitlab/bpfd-portal";
import { FormattedMessage } from "react-intl";
import { YesNoValueEnum } from "../../../lib/enum";
import { RetirementChoiceSubmitData } from "../../../lib/types";
import GenericTwoColumnTable from "../../atoms/GenericTwoColumnTable/GenericTwoColumnTable";
import GenericTableDiv from "../../atoms/GenericTableDiv";

interface SurrenderConfirmationPopupProps {
  showPopup: boolean;
  setShowPopup: (showPopup: boolean) => void;
  payload: RetirementChoiceSubmitData;
  isSubmitting: boolean;
  onClose: (payload?: RetirementChoiceSubmitData) => void;
}

const SurrenderConfirmationPopup: React.FC<SurrenderConfirmationPopupProps> = ({
  showPopup,
  payload,
  onClose,
  isSubmitting,
}) => {
  const { data, button } = payload;
  const selectedTaxOption =
    data?.taxReductionOptions?.find((item) => item.checked)?.value === YesNoValueEnum.YES;
  const selectedDisabilityOption =
    data?.disabledOptions?.find((item) => item.checked)?.value === YesNoValueEnum.YES;

  const LeftColumn = [
    <FormattedMessage key="bank-account" id="utils.bank-account" />,
    <FormattedMessage key="tax-exception" id="utils.tax-exception" />,
    <FormattedMessage key="disability" id="planner.disability" />,
  ];

  const rightColumn = [
    data?.bankAccount?.bankNumber.toString(),
    <FormattedMessage key="tax-exception-value" id={`utils.${selectedTaxOption ? "yes" : "no"}`} />,
    <FormattedMessage key="disability-value" id={`utils.${selectedDisabilityOption ? "yes" : "no"}`} />,
  ];

  const popupContent = (
    <GenericTextBlock
      size="s"
      className="mb-0"
      content={
        <>
          <GenericTableDiv>
            {button?.text}
            <GenericTwoColumnTable
              leftValues={LeftColumn}
              rightValues={rightColumn}
              boldRight={rightColumn}
            />
          </GenericTableDiv>
          <FormattedMessage id="retirement-choice.surrender.digi-sign-no" />
        </>
      }
    />
  );

  return (
    <Popup
      open={showPopup}
      heading={<FormattedMessage id="retirement-choice.surrender.confirm-header" />}
      body={popupContent}
      onClose={onClose}
      popupVariant="confirmation"
      buttons={[
        <Button
          key={"cancel"}
          size="md"
          onClick={() => {
            onClose();
          }}
          text={<FormattedMessage id="utils.cancel" />}
          disabled={isSubmitting}
        />,
        <Button
          key={"agree"}
          size="md"
          onClick={() => onClose?.({ data: data, button: button, isSigned: true })}
          text={<FormattedMessage id="utils.agree" />}
          disabled={isSubmitting}
        />,
      ]}
      width={1000}
      preventBackdropClick
    />
  );
};

export default SurrenderConfirmationPopup;
