import { PageLayout, TabCollection, TextHeader } from "@faraday-gitlab/bpfd-portal";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";
import { useAuthentication } from "../../context/AuthenticatedContext";
import useScreenWidth, { Breakpoints } from "../../hooks/useScreenWidth";
import { MYDATA_ACTIVE_KEY } from "../../lib/enum";
import { Preferences } from "../../lib/types";
import { getCommunicationPreferences } from "../../react-query/queries";
import { HREF } from "../../routes/routes";
import { getPreferencesOrDefault } from "../../utils/defaultPreferences";
import { useQueryTokenRefresh } from "../../hooks/useQueryTokenRefresh";
import Loading from "../organisms/Loading";
import MyDataFirstTab from "../organisms/myData/MyDataFirstTab";
import MyDataSecondTab from "../organisms/myData/MyDataSecondTab";
import MyDataThirdTab from "../organisms/myData/MyDataThirdTab";
import RequestError from "../organisms/RequestError";
import FlexContainer from "../templates/FlexContainer";
import StandardGrid from "../templates/grid/StandardGrid";
import { preferencesKeys } from "../../react-query/query-keys";

const MyData = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const defaultActiveKey = searchParams?.get("tab") ? searchParams.get("tab") : MYDATA_ACTIVE_KEY.DEFAULT;
  const [activeKey, setActiveKey] = useState(+(defaultActiveKey as string));
  const screenWidth = useScreenWidth();
  const data = useAuthentication();
  const {
    data: preferences,
    isLoading: comIsLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: preferencesKeys.user(data?.user?.id),
    queryFn: getCommunicationPreferences,
    select: (data) => getPreferencesOrDefault(data.preferences[0] as Preferences),
    enabled: !!data?.user?.id,
  });

  useQueryTokenRefresh(error, refetch);

  const paymentDetailsId =
    screenWidth >= Breakpoints.LG ? "my-data.payment-information" : "my-data.payment-details-mobile";

  const tabs = [
    <FormattedMessage id="my-data.my-data-tab" key="my-data.my-data" />,
    <FormattedMessage id="my-data.correspondence-settings" key="my-data.correspondence-settings" />,
    <FormattedMessage id={paymentDetailsId} key="my-data.payment-information" />,
  ];

  const handleSetActiveTab = (tab: number) => {
    setActiveKey(tab);
    if (tab === 0) {
      navigate(HREF.MY_DATA);
    } else if (tab === 1) {
      navigate(HREF.MY_DATA_TAB_2);
    } else if (tab === 2) {
      navigate(HREF.MY_DATA_TAB_3);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tab = parseInt(params.get("tab") ?? "0");
    if (!isNaN(tab)) {
      setActiveKey(tab);
    }
  }, [location.search]);

  if (data?.isLoading || comIsLoading) {
    return <Loading />;
  }

  if (!data?.user || !preferences) {
    return <RequestError />;
  }

  return (
    <PageLayout>
      <Fragment key=".0">
        <TextHeader
          subText=""
          tabCollection={
            <TabCollection
              activeKey={+activeKey}
              className="pt-20"
              contents={tabs}
              handleSetActiveKey={handleSetActiveTab}
            />
          }
          text={<FormattedMessage id="my-data.my-data-tab" />}
        />
        <FlexContainer>
          <StandardGrid>
            {activeKey === MYDATA_ACTIVE_KEY.DEFAULT && <MyDataFirstTab user={data.user} />}
            {activeKey === MYDATA_ACTIVE_KEY.SETTINGS && (
              <MyDataSecondTab preferences={preferences} user={data.user} />
            )}
            {activeKey === MYDATA_ACTIVE_KEY.BANK_ACCOUNT && <MyDataThirdTab user={data.user} />}
          </StandardGrid>
        </FlexContainer>
      </Fragment>
    </PageLayout>
  );
};

export default MyData;
