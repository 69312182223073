import { ReactNode } from "react";
import FlexContainer from "./FlexContainer";

interface StandardFormWrapperProps {
  children: ReactNode;
}

const StandardFormWrapper: React.FC<StandardFormWrapperProps> = ({ children }) => {
  return (
    <div className="bg-gradient-mint-blue-light">
      <FlexContainer>
        <div className="grid grid-cols-12">
          <div className="col-span-12 md:col-span-5 md:col-start-4 space-y-4">{children}</div>
        </div>
      </FlexContainer>
    </div>
  );
};

export default StandardFormWrapper;
