import { FormTextBlock, FormField, Input, FormSubmit } from "@faraday-gitlab/bpfd-portal";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { useEffect } from "react";
import { useForm, FieldValues } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { useAuthentication } from "../../../context/AuthenticatedContext";
import { TGivenNameSchema, useGivenNameSchema } from "../../../hooks/schemas/generalSchema";
import { Preferences } from "../../../lib/types";
import { updateUserContactDetails } from "../../../react-query/mutations";
import { HREF } from "../../../routes/routes";
import Loading from "../../organisms/Loading";
import { handleExpiredToken, RetryCallback } from "../../../utils/handleExpiredToken";
import StandardFormWrapper from "../../templates/StandardFormWrapper";
import { userKeys } from "../../../react-query/query-keys";

export const UserDataForm = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const data = useAuthentication();
  const user = data?.user;

  const {
    mutateAsync,
    isPending,
    error: postError,
    reset,
  } = useMutation({
    mutationFn: updateUserContactDetails,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: userKeys.all,
      });
      navigate(HREF.MY_DATA);
    },
    onError: async (postError, data): Promise<void> => {
      handleExpiredToken(postError, mutateAsync as RetryCallback, data);
      setError("givenName", { message: postError?.message });
    },
  });

  const {
    handleSubmit,
    control,
    setValue,
    setError,
    formState: { errors, isSubmitting, isDirty, isValid },
  } = useForm<TGivenNameSchema>({
    mode: "onChange",
    resolver: zodResolver(useGivenNameSchema()),
    defaultValues: {
      givenName: user?.givenName,
    },
  });

  useEffect(() => {
    setValue("givenName", user?.givenName ?? "");
  }, [user, setValue]);

  const onSubmit = async (data: FieldValues) => {
    await mutateAsync({ givenName: data.givenName } as Preferences);
    reset();
  };

  if (data?.isLoading) {
    return <Loading />;
  }

  return (
    <StandardFormWrapper>
      <form onSubmit={handleSubmit((data) => onSubmit(data))}>
        <FormTextBlock heading={<FormattedMessage id="my-data.my-data-tab" />} />
        <FormField id="givenName" labelText={<FormattedMessage id="utils.givenName" />}>
          <Input
            id="givenName"
            name="givenName"
            control={control}
            disabled={isSubmitting || isPending}
            error={!!errors.givenName}
          />
        </FormField>
        <FormField id="givenName">
          <FormSubmit
            buttons={[
              {
                size: "md",
                ariaLabel: "button",
                text: <FormattedMessage id="utils.save" />,
                type: "submit",
                disabled: !isDirty || isSubmitting || !isValid,
              },
              {
                size: "md",
                ariaLabel: "link",
                onClick: () => {
                  navigate(-1);
                },
                text: <FormattedMessage id="utils.cancel" />,
              },
            ]}
            errorMessage={postError?.message}
          />
        </FormField>
      </form>
    </StandardFormWrapper>
  );
};
