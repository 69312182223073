import { PageLayout } from "@faraday-gitlab/bpfd-portal";
import { useQuery } from "@tanstack/react-query";
import { getValueTransfer } from "../../../react-query/queries";
import Loading from "../../organisms/Loading";
import RequestError from "../../organisms/RequestError";
import { useQueryTokenRefresh } from "../../../hooks/useQueryTokenRefresh";
import ValueTransferApprovalCase from "../../organisms/actions/valueTransferApproval/ValueTransferApprovalCase";
import { ValueTransferApprovalCaseType } from "../../../lib/types";
import { useState } from "react";
import ThankYouMessage from "../../atoms/ThankYouMessage";
import BackButton from "../../atoms/BackButton";
import { valuetransferKeys } from "../../../react-query/query-keys";

const ValueTransferApprovalPage: React.FC = () => {
  const {
    data: openCases,
    error,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: valuetransferKeys.all,
    queryFn: getValueTransfer,
    select: (data) => data.valueTransfer.valueTransferApprovalCases as ValueTransferApprovalCaseType[],
  });

  useQueryTokenRefresh(error, refetch);

  const [isSuccessScreen, setIsSuccessScreen] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState(<></>);

  const showThankYouMessage = (message: JSX.Element) => {
    setIsSuccessScreen(true);
    setConfirmationMessage(message);
  };

  if (isLoading) {
    return <Loading />;
  }

  if (!openCases || openCases.length === 0 || error) {
    return <RequestError />;
  }

  return (
    <PageLayout>
      {isSuccessScreen ? (
        <>
          <ThankYouMessage body={confirmationMessage} />
          <div style={{ marginTop: "5rem" }}>
            <BackButton />
          </div>
        </>
      ) : (
        openCases.map((openCase, i) => (
          <ValueTransferApprovalCase
            openCase={openCase}
            showThankYouMessage={showThankYouMessage}
            key={openCase.caseId}
            hasBottomBorder={i !== openCases.length - 1}
          />
        ))
      )}
    </PageLayout>
  );
};

export default ValueTransferApprovalPage;
