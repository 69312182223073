import { FormImportantMessage } from "@faraday-gitlab/bpfd-portal";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";
import { noSlashPath } from "../../routes/router";

const RequestError: React.FC = () => {
  const location = useLocation().pathname;
  const path = noSlashPath(location === "/" ? "/homepage" : location);

  return (
    <div className="container">
      <FormImportantMessage
        heading={<FormattedMessage id="error.generic-header" />}
        body={
          <FormattedMessage
            id="error.generic-body"
            values={{ name: <FormattedMessage id={`error.${path}`} /> }}
          />
        }
        className="mt-10 mb-4 mx-auto"
      />
    </div>
  );
};

export default RequestError;
