import { FieldValues } from "react-hook-form";
import { EARLIER_LATER_FULLY } from "../lib/constants";
import { Employment, GenericRadioItem, PlannerBridgingPayload } from "../lib/types";
import { RadioButtonProps } from "@faraday-gitlab/bpfd-portal";
import { UseMaximumExchange } from "../lib/enum";

export const getPlannerBridgingPayload = (
  dataForm: FieldValues,
  employmentInfo: Employment
): PlannerBridgingPayload => {
  const fullyOrPartialValue =
    dataForm?.retirementOption?.earlierOrLaterRetirementOptions?.fullyOrPartial?.value;
  const isExchangeSelected = dataForm?.retirementOption?.exchangeOption?.isSelected;
  const isHighLowSelected = dataForm?.retirementOption?.highLowLowHigh?.isSelected;

  const getExchangeType = () => {
    const exchangeType = dataForm?.retirementOption?.exchangeOption?.exchangeType;
    if (typeof exchangeType === "string") return exchangeType;

    return exchangeType.find((item: GenericRadioItem) => item.checked).value;
  };

  const getIsMaxExchange = () => {
    const isMaxExchange = dataForm?.retirementOption?.exchangeOption?.isMaxExchange;
    if (typeof isMaxExchange === "boolean") return isMaxExchange;

    return isMaxExchange.find((item: GenericRadioItem) => item.checked).value === UseMaximumExchange.YES;
  };

  const fullAgeMonth = dataForm?.retirementOption?.earlierOrLaterRetirementOptions?.fullAgeMonth;

  return {
    salary: employmentInfo?.salary,
    hours: employmentInfo?.hours,
    employmentOptions: {
      isWorkingTimeSelected: dataForm?.employmentOptions?.workingTimeOption?.isSelected,
      isEarningSalarySelected: dataForm?.employmentOptions?.earningSalaryOption?.isSelected,
    },
    retirementOptions: {
      earlyOrLater: {
        isSelected:
          dataForm.retirementOption?.earlierOrLaterRetirementOptions?.isEarlierOrLaterRetirementSelected,
        percentage: fullyOrPartialValue,
        partlyYears:
          fullyOrPartialValue !== EARLIER_LATER_FULLY
            ? dataForm?.retirementOption?.earlierOrLaterRetirementOptions?.partialAgeYear
            : null,
        partlyMonths:
          fullyOrPartialValue !== EARLIER_LATER_FULLY
            ? dataForm?.retirementOption?.earlierOrLaterRetirementOptions?.partialAgeMonth
            : null,
        fullyYears:
          fullyOrPartialValue === EARLIER_LATER_FULLY
            ? dataForm?.retirementOption?.earlierOrLaterRetirementOptions?.fullAgeYear
            : null,
        fullyMonths: fullyOrPartialValue === EARLIER_LATER_FULLY && fullAgeMonth ? fullAgeMonth : null,
      },
      exchange: {
        isSelected: isExchangeSelected,
        exchangeType: isExchangeSelected ? getExchangeType() : null,
        isMaxExchangeSelected: isExchangeSelected ? getIsMaxExchange() : false,
        exchangeAmount: isExchangeSelected
          ? +dataForm?.retirementOption?.exchangeOption?.exchangeAmount
          : null,
      },
      highLow: {
        isSelected: isHighLowSelected,
        type: isHighLowSelected
          ? dataForm?.retirementOption?.highLowLowHigh?.exchangeOptions?.find(
              (item: RadioButtonProps) => item.checked
            )?.value
          : null,
        months: isHighLowSelected ? dataForm?.retirementOption?.highLowLowHigh?.numberOfMonths : null,
      },
    },
  };
};
