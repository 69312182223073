import { z } from "zod";
import { NETHERLANDS_BRP } from "../../lib/constants";
import { useIntlMessage } from "../useIntlMessage";

export const usePartnerDetailSchema = (country: string, bsn: string) => {
  const intlMessage = useIntlMessage();
  return z
    .object({
      firstName: z.string().optional(),
      lastName: z.string().optional(),
      middleName: z.string().optional(),
      gender: z.string().min(1, intlMessage("partner-detail.validation.required")),
      partnerBsn: z.string().superRefine((data, ctx) => {
        if (
          (NETHERLANDS_BRP.includes(country) && data.length < 9) ||
          (!NETHERLANDS_BRP.includes(country) && data.length > 0 && data.length < 9)
        ) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: intlMessage("validation.bsn.invalid"),
          });
        }
      }),
      partnerDateOfBirth: z
        .date()
        .optional()
        .superRefine((data, ctx) => {
          if (!data) {
            ctx.addIssue({
              code: z.ZodIssueCode.invalid_date,
              message: intlMessage("partner-detail.validation.required"),
            });
          }
        }),
      startDate: z
        .date()
        .optional()
        .superRefine((data, ctx) => {
          if (!data) {
            ctx.addIssue({
              code: z.ZodIssueCode.invalid_date,
              message: intlMessage("partner-detail.validation.required"),
            });
          }
          if (data && data > new Date(Date.now())) {
            ctx.addIssue({
              code: z.ZodIssueCode.invalid_date,
              message: intlMessage("partner-detail.validation.startdate.future"),
            });
          }
        }),
    })
    .superRefine((data, ctx) => {
      if (data.partnerBsn === bsn) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: intlMessage("validation.bsn.duplicate"),
        });
      }
      if (country.length > 0 && !NETHERLANDS_BRP.includes(country)) {
        if (!data.firstName || data.firstName.length === 0) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: intlMessage("partner-detail.validation.required"),
            path: ["firstName"],
          });
        }
        if (data.firstName && data.firstName.length > 40) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: intlMessage("validation.firstName.length"),
            path: ["firstName"],
          });
        }
        if (!data.lastName || data.lastName.length === 0) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: intlMessage("validation.required"),
            path: ["lastName"],
          });
        }
        if (data.lastName && data.lastName.length > 80) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: intlMessage("validation.lastName.length"),
            path: ["lastName"],
          });
        }
      }
    });
};

export const useEndCohabitationSchema = () => {
  const intlMessage = useIntlMessage();

  return z.object({
    endDate: z
      .date()
      .optional()
      .superRefine((data, ctx) => {
        if (!data) {
          ctx.addIssue({
            code: z.ZodIssueCode.invalid_date,
            message: intlMessage("partner-detail.validation.required"),
          });
        }
      }),
  });
};

export type PartnerDetail = z.infer<ReturnType<typeof usePartnerDetailSchema>>;
export type EndCohabitation = z.infer<ReturnType<typeof useEndCohabitationSchema>>;
