import { useEffect } from "react";
import { QueryObserverResult } from "@tanstack/react-query";
import { refreshAuthToken, UNAUTHORIZED_ERROR_CODE } from "../utils/token";

interface QueryError {
  message: string;
}

export const useQueryTokenRefresh = (
  error: QueryError | null,
  refetch: () => Promise<QueryObserverResult>
) => {
  useEffect(() => {
    const handleRefreshToken = async () => {
      const refreshToken = await refreshAuthToken();
      if (refreshToken?.accessToken) {
        refetch();
      }
    };

    if (error?.message === UNAUTHORIZED_ERROR_CODE) {
      handleRefreshToken();
    }
  }, [error, refetch]);
};
