import { FormField, FormSubmit, FormTextBlock, Text } from "@faraday-gitlab/bpfd-portal";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Control, FieldValues, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { useAuthentication } from "../../../context/AuthenticatedContext";
import { TMyDataBankAccount, useMyDataBankAccount } from "../../../hooks/schemas/useMyDataBankAccount";
import useCountries from "../../../hooks/useCountries";
import { useIntlMessage } from "../../../hooks/useIntlMessage";
import { PaymentOptionValue } from "../../../lib/constants";
import { YesNoValueEnum } from "../../../lib/enum";
import { CountryByUserLng, Person } from "../../../lib/types";
import { editBankAccount, editTaxException } from "../../../react-query/mutations";
import { userKeys } from "../../../react-query/query-keys";
import { HREF } from "../../../routes/routes";
import { handleExpiredToken, RetryCallback } from "../../../utils/handleExpiredToken";
import yesNoOptions from "../../../utils/YesNoOptions";
import TaxException from "../../atoms/TaxException";
import YesNoRadio from "../../atoms/YesNoRadio";
import Loading from "../../organisms/Loading";
import StandardFormWrapper from "../../templates/StandardFormWrapper";
import BankAccountForm from "../BankAccountForm";
import { formatStringWithSpaces, toAlphanumeric } from "../../../utils/string";

interface Props {
  user?: Person;
  countries?: CountryByUserLng[];
}

const UserPaymentInformationChild = ({ user, countries }: Props) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const intlMessage = useIntlMessage();
  const intl = useIntl();

  const personalBankAccount = user?.personalBankAccount;
  const country = countries?.find((country) => country.countryCode === personalBankAccount?.countryCode);

  const paymentOptions = [
    {
      label: intlMessage("passing-away.within-eu"),
      checked: personalBankAccount?.isBankAccountEU !== false,
      value: PaymentOptionValue.InsideEU,
      id: "1",
    },
    {
      label: intlMessage("passing-away.outside-eu"),
      checked: personalBankAccount?.isBankAccountEU === false,
      value: PaymentOptionValue.OutsideEU,
      id: "2",
    },
  ];

  const wageTaxOptions = yesNoOptions(intl, "tax", {
    yes: user?.hasTaxException === true,
    no: user?.hasTaxException !== true,
  });

  const defaultValues = {
    bankAccount: {
      paymentOptions,
      bankNumber: personalBankAccount?.bankAccount
        ? formatStringWithSpaces(toAlphanumeric(personalBankAccount?.bankAccount), 4)
        : "",
      bankName: personalBankAccount?.bankName ?? undefined,
      bankCode: personalBankAccount?.bankBicCode ?? undefined,
      bankAddress: personalBankAccount?.bankAddress ?? undefined,
      bankLocation: personalBankAccount?.locationBank ?? undefined,
      bankLand: country
        ? { label: country.countryName, value: country.countryCode, id: country.countryCode }
        : undefined,
    },
    wageTaxOptions,
  };

  const { handleSubmit, control, watch } = useForm<TMyDataBankAccount>({
    mode: "onChange",
    resolver: zodResolver(useMyDataBankAccount()),
    defaultValues,
  });

  const {
    mutateAsync: mutateBankAccount,
    isPending: isPendingEditBankAccount,
    error: bankAccountError,
  } = useMutation({
    mutationFn: editBankAccount,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: userKeys.all,
      });
    },
    onError: async (postError, data): Promise<void> => {
      handleExpiredToken(postError, mutateBankAccount as RetryCallback, data);
    },
  });

  const {
    mutateAsync: mutateTaxException,
    isPending: isPendingEditTaxException,
    error: taxError,
  } = useMutation({
    mutationFn: editTaxException,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: userKeys.all,
      });
    },
    onError: async (postError, data): Promise<void> => {
      handleExpiredToken(postError, mutateTaxException as RetryCallback, data);
    },
  });

  const [watchedPaymentOptions] = watch(["bankAccount.paymentOptions"]);

  const onSubmit = async (values: TMyDataBankAccount) => {
    const bankAccountPayload = {
      locationBank: values.bankAccount.bankLocation,
      isBankAccountEU:
        values.bankAccount?.paymentOptions?.find((item) => item.checked)?.value ===
        PaymentOptionValue.InsideEU,
      countryName: values.bankAccount.bankLand?.label,
      countryCode: values.bankAccount.bankLand?.value,
      bankName: values?.bankAccount?.bankName,
      bankBicCode: values.bankAccount?.bankCode,
      bankAddress: values.bankAccount?.bankAddress,
      bankAccount: values.bankAccount.bankNumber,
    };

    const taxPayload = {
      isTaxException: values.wageTaxOptions?.find((item) => item.checked)?.value === YesNoValueEnum.YES,
    };

    await Promise.all([mutateTaxException(taxPayload), mutateBankAccount(bankAccountPayload)]);

    navigate(HREF.MY_DATA_TAB_3);
  };

  const isInsideEU =
    watchedPaymentOptions?.find((item) => item.checked)?.value === PaymentOptionValue.InsideEU;
  const isPending = isPendingEditBankAccount || isPendingEditTaxException;

  return (
    <StandardFormWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormTextBlock heading={<FormattedMessage id="my-data.edit-payment-information" />} />
        <BankAccountForm
          control={control as unknown as Control<FieldValues>}
          name="bankAccount"
          isInsideEU={isInsideEU}
        />
        <FormField id="wage-tax" className="col-span-12">
          <Text className="mb-2 mt-4 flex justify-between items-center mb-5">
            <TaxException />
          </Text>
          <YesNoRadio name="wageTaxOptions" control={control} />
        </FormField>

        <div className="mt-4">
          <FormSubmit
            buttons={[
              {
                size: "md",
                ariaLabel: "button",
                text: <FormattedMessage id="utils.save" />,
                type: "submit",
                disabled: isPending,
              },
              {
                size: "md",
                ariaLabel: "button",
                text: <FormattedMessage id="utils.cancel" />,
                type: "button",
                onClick: () => {
                  navigate(HREF.MY_DATA_TAB_3);
                },
                disabled: isPending,
              },
            ]}
            errorMessage={bankAccountError?.message ?? taxError?.message}
          />
        </div>
      </form>
    </StandardFormWrapper>
  );
};

const UserPaymentInformation = () => {
  const { user, isUserPending } = useAuthentication() ?? {};
  const { countriesByUserLng, isPending: isPendingCountries } = useCountries();

  if (isUserPending || isPendingCountries) {
    return <Loading />;
  }

  return <UserPaymentInformationChild user={user} countries={countriesByUserLng} />;
};

export default UserPaymentInformation;
