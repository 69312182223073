import { FormImportantMessage, GenericTextBlock, Button } from "@faraday-gitlab/bpfd-portal";
import { useMutation } from "@tanstack/react-query";
import { formatDate } from "date-fns";
import { useState, SetStateAction, useEffect, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { Fragment } from "react/jsx-runtime";
import useExternalWindow, { RedirectUrls } from "../../../../hooks/useExternalWindow";
import useScrollTo from "../../../../hooks/useScrollTo";
import { EMPTY_STRING } from "../../../../lib/constants";
import { updateValueTransferApproval, updatePartnerAcceptance } from "../../../../react-query/mutations";
import { HREF } from "../../../../routes/routes";
import { reverseDate } from "../../../../utils/reverseDate";
import { UNAUTHORIZED_ERROR_CODE } from "../../../../utils/token";
import GenericTwoColumnTable from "../../../atoms/GenericTwoColumnTable/GenericTwoColumnTable";
import { ValueTransferApprovalCaseType } from "../../../../lib/types";
import { AcceptanceStatus } from "../../../../lib/enum";
import { format } from "../../../../utils/formatNumber";
import { handleExpiredToken, RetryCallback } from "../../../../utils/handleExpiredToken";
import "./ValueTransferApprovalCase.css";
import GenericTableDiv from "../../../atoms/GenericTableDiv";

const formattedAmount = (amount: number | null) => {
  if (amount === null) return EMPTY_STRING;
  return `€ ${format(amount)}`;
};

interface ValueTransferCase {
  openCase: ValueTransferApprovalCaseType;
  showThankYouMessage: (message: JSX.Element) => void;
  hasBottomBorder?: boolean;
}

const ValueTransferApprovalCase: React.FC<ValueTransferCase> = ({
  openCase,
  showThankYouMessage,
  hasBottomBorder,
}) => {
  const { ref, scrollToView } = useScrollTo<HTMLDivElement>();
  const { openLoginWindow, externalPopup, loginResponse, setLoginResponse } = useExternalWindow(
    HREF.LOGIN_PARTNER
  );

  const {
    dateValueTransferQuote,
    valuePPFromSystem,
    valueOPFromSystem,
    firstName,
    middleName,
    lastName,
    bsn,
    birthDate,
    personId: pensionNumber,
    thirdPartyName,
    transferValueQuoteTotal,
    systemDateQuote,
    providerName,
    digitalSignIdPartner,
    digitalSignIdParticipant,
    partnerBSN,
    partnerId,
    valueTransferExPartners,
  } = openCase;

  const [hasPartner, setHasPartner] = useState<boolean>(false);
  const [hasRejected, setHasRejected] = useState<boolean>(false);
  const [hasParticipantAccepted, setHasParticipantAccepted] = useState<boolean>(false);
  const [hasPartnerAccepted, setHasPartnerAccepted] = useState<boolean>(false);
  const [hasParticipantAlreadySubmitted, setHasParticipantAlreadySubmitted] = useState<boolean>(false);
  const [hasPartnerAlreadySubmitted, setHasPartnerAlreadySubmitted] = useState<boolean>(false);

  const {
    mutateAsync: approveValueTransfer,
    isPending: isSubmitPending,
    isSuccess: isSubmitSuccess,
    error: submitError,
  } = useMutation({
    mutationFn: updateValueTransferApproval,
    onSuccess: () => {
      if (isSubmitted) {
        scrollToView();
      }
    },
    onError: async (postError, data): Promise<void> => {
      if (postError?.message === UNAUTHORIZED_ERROR_CODE) {
        handleExpiredToken(postError, approveValueTransfer as RetryCallback, data);
      } else {
        scrollToView();
      }
    },
  });

  const { mutate: approvePartner, reset: resetPartnerApproval } = useMutation({
    mutationFn: updatePartnerAcceptance,
    onSuccess: (data: { redirectUrls: SetStateAction<RedirectUrls | undefined>; state: string }) => {
      openLoginWindow(data);
    },
    onError: async (postError: unknown): Promise<void> => {
      console.log("postError", postError);
      resetPartnerApproval();
    },
  });

  // partner window
  const approvalStatus = loginResponse?.approved;
  const approvalTrackingCode = loginResponse?.state;
  const isApproved = approvalStatus && approvalTrackingCode === loginResponse.state;
  const isWindowOpen = externalPopup !== null;

  // signature status
  const hasPartnerSigned = digitalSignIdPartner && digitalSignIdPartner !== EMPTY_STRING;
  const hasParticipantSigned = digitalSignIdParticipant && digitalSignIdParticipant !== EMPTY_STRING;

  // acceptance status
  const hasAccepted = hasParticipantAccepted && (!hasPartner || hasPartnerAccepted);
  const isSubmitted = (hasRejected || hasAccepted) && isSubmitSuccess;

  // confirmation message
  const messageStatus = hasRejected ? "rejected" : "accepted";
  const confirmationMessage = useMemo(
    () => <FormattedMessage id={`value-transfer-approval.${messageStatus}`} />,
    [messageStatus]
  );

  // pending message. in some cases, the back-end may take several minutes to process the request.
  // The case will therefore be marked as pending before being removed
  // this delay does not affect case rejection. Only approval.
  const isProcessPending = !!hasParticipantSigned && !!(!hasPartner || hasPartnerSigned);

  // first table
  const QuotationValueTransfer = <FormattedMessage id={"value-transfer-approval.quotation"} />;
  const todayDate = [formatDate(new Date(), "dd-MM-yyyy")];

  // Your details table
  const jouwGegevensKeys = [
    <FormattedMessage id="utils.name" key="utils.name" />,
    <FormattedMessage id="utils.pension-number" key="utils.pension-number" />,
    <FormattedMessage id="utils.born-date" key="utils.born-date" />,
    <FormattedMessage id="utils.bsn" key="utils.bsn" />,
  ];
  const jouwGegevensValues = [
    `${firstName ?? EMPTY_STRING} ${middleName ?? EMPTY_STRING} ${lastName ?? EMPTY_STRING}`,
    pensionNumber,
    reverseDate(birthDate),
    bsn,
  ];

  // old pension table
  const oldPensionKey = [<FormattedMessage id="value-transfer-approval.old-pension" key="op" />];
  const oldPensionValue = [formattedAmount(valueOPFromSystem)];
  // ex partner table
  const exPartners = valueTransferExPartners;
  const exPartnerLabel = <FormattedMessage id="value-transfer-approval.ex-partner" />;
  const exPartnerKeys = exPartners?.map((exPartner) => exPartner.name);
  const exPartnerValues = exPartners?.map((exPartner) =>
    formattedAmount(exPartner.transfervalueQuoteEqualizedOP)
  );
  // partner pension table
  const partnerPensionKey = [<FormattedMessage id="value-transfer-approval.partner-pension" key="pp" />];
  const partnerPensionValue = [formattedAmount(valuePPFromSystem)];

  // button labels
  const partnerLabel = (
    <FormattedMessage id={`utils.${hasPartnerAccepted ? "approved-partner" : "accept-partner"}`} />
  );
  const participantLabel = (
    <FormattedMessage id={`utils.${hasParticipantAccepted ? "approved" : "accept"}`} />
  );

  const handleReject = () => {
    setHasRejected(true);
  };

  const handleAcceptParticipant = () => {
    setHasParticipantAccepted(true);
  };

  const handleAcceptPartner = () => {
    if (hasPartner && !isWindowOpen) {
      approvePartner();
    }
  };

  // handles initial partner status
  useEffect(() => {
    if (partnerId && partnerId !== EMPTY_STRING && !hasPartner) {
      setHasPartner(true);
    }
    if (partnerBSN && partnerBSN !== EMPTY_STRING && !hasPartner) {
      setHasPartner(true);
    }
  }, [partnerId, partnerBSN, hasPartner]);

  // handles initial acceptance status
  useEffect(() => {
    if (hasPartnerSigned && !hasPartnerAccepted) {
      setHasPartnerAccepted(true);
      setHasPartnerAlreadySubmitted(true);
    }
    if (hasParticipantSigned && !hasParticipantAccepted) {
      setHasParticipantAccepted(true);
      setHasParticipantAlreadySubmitted(true);
    }
  }, [hasParticipantAccepted, hasParticipantSigned, hasPartnerAccepted, hasPartnerSigned]);

  // handles partner approval
  useEffect(() => {
    if (isApproved && !hasPartnerAccepted) {
      setHasPartnerAccepted(true);
    }
  }, [hasPartnerAccepted, isApproved, loginResponse]);

  //handle final Submit
  useEffect(() => {
    const canSubmitParticipant = hasParticipantAccepted && !hasParticipantAlreadySubmitted;
    const canSubmitPartner = hasPartnerAccepted && !hasPartnerAlreadySubmitted && hasPartner;

    if (hasRejected) {
      approveValueTransfer({ ...openCase, acceptanceStatus: AcceptanceStatus.REJECTED });
    }
    if (canSubmitParticipant) {
      approveValueTransfer({ ...openCase, acceptanceStatus: AcceptanceStatus.ACCEPTED_PARTICIPANT });
    }
    if (canSubmitPartner) {
      approveValueTransfer({ ...openCase, acceptanceStatus: AcceptanceStatus.ACCEPTED_PARTNER });
      setLoginResponse(undefined);
    }
  }, [
    approveValueTransfer,
    hasParticipantAccepted,
    hasParticipantAlreadySubmitted,
    hasPartner,
    hasPartnerAccepted,
    hasPartnerAlreadySubmitted,
    hasRejected,
    openCase,
    setLoginResponse,
  ]);

  //shows success message for the entire page
  useEffect(() => {
    if (isSubmitted) {
      showThankYouMessage(confirmationMessage);
    }
  }, [confirmationMessage, isSubmitted, showThankYouMessage]);

  return (
    <Fragment key=".0">
      <div
        ref={ref}
        style={{ borderBottom: hasBottomBorder ? "2px solid black" : EMPTY_STRING }}
        className="container flex-grow py-20">
        {submitError && (
          <FormImportantMessage
            className="mb-6"
            heading={<FormattedMessage id="error.validation-fail.title" />}
            body={<FormattedMessage id="error.request" />}
          />
        )}
        <GenericTextBlock
          size="m"
          heading={<FormattedMessage id="value-transfer-approval.avt" />}
          content={
            isProcessPending ? (
              <FormImportantMessage
                className="mb-6"
                heading={<FormattedMessage id="value-transfer-approval.ongoing.title" />}
                body={<FormattedMessage id="value-transfer-approval.ongoing.body" />}
              />
            ) : (
              <>
                <GenericTableDiv>
                  <GenericTwoColumnTable
                    leftValues={[QuotationValueTransfer]}
                    boldLeft={[QuotationValueTransfer]}
                    rightValues={[todayDate]}
                  />
                  <FormattedMessage id="value-transfer-approval.offer" />
                </GenericTableDiv>
                <GenericTableDiv>
                  <strong>{<FormattedMessage id="utils.your-details" />}</strong>
                  <GenericTwoColumnTable leftValues={jouwGegevensKeys} rightValues={jouwGegevensValues} />
                </GenericTableDiv>
                <GenericTableDiv>
                  <GenericTwoColumnTable
                    leftValues={[
                      <FormattedMessage
                        id="value-transfer-approval.accrued-value"
                        values={{
                          provider: thirdPartyName,
                          date: dateValueTransferQuote,
                        }}
                        key="transferValueQuoteTotal"
                      />,
                    ]}
                    rightValues={[formattedAmount(transferValueQuoteTotal)]}
                  />
                </GenericTableDiv>
                <GenericTableDiv>
                  <FormattedMessage
                    id="value-transfer-approval.claims"
                    values={{
                      date: systemDateQuote,
                      provider: providerName,
                    }}
                  />
                  <div>
                    <FormattedMessage id="value-transfer-approval.entitlements" />
                  </div>
                  <GenericTwoColumnTable leftValues={oldPensionKey} rightValues={oldPensionValue} />
                  {exPartners.length > 0 && (
                    <>
                      <>{exPartnerLabel}</>
                      <GenericTwoColumnTable
                        leftValues={exPartnerKeys}
                        paddingLeft={exPartnerKeys}
                        rightValues={exPartnerValues}
                      />
                    </>
                  )}
                  <GenericTwoColumnTable leftValues={partnerPensionKey} rightValues={partnerPensionValue} />
                </GenericTableDiv>
                <GenericTableDiv>
                  <FormattedMessage id="value-transfer-approval.quotation-amounts" />
                </GenericTableDiv>
                <GenericTableDiv>
                  <FormattedMessage
                    id={`value-transfer-approval.agree-${hasPartner ? "with-partner" : "solo"}`}
                  />
                </GenericTableDiv>
              </>
            )
          }
        />
        <div className="buttons-row">
          <div className="cancel-button">
            <Button
              text={<FormattedMessage id="utils.reject" />}
              ariaLabel={<FormattedMessage id="utils.reject" />}
              onClick={handleReject}
              disabled={isSubmitPending || hasRejected || hasParticipantAccepted || isProcessPending}
              arrowPosition="left"
              size="md"
              type="button"
            />
          </div>
          <div className="participant-button">
            <Button
              text={participantLabel}
              ariaLabel={participantLabel}
              onClick={handleAcceptParticipant}
              disabled={
                isSubmitPending || hasParticipantAccepted || !!digitalSignIdParticipant || isProcessPending
              }
              size="md"
              type="button"
            />
          </div>
          {hasPartner && (
            <div className="partner-button">
              <Button
                text={partnerLabel}
                ariaLabel={partnerLabel}
                onClick={handleAcceptPartner}
                disabled={isSubmitPending || hasPartnerAccepted || !!digitalSignIdPartner || isProcessPending}
                size="md"
                type="button"
              />
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default ValueTransferApprovalCase;
