import { InformationPanel, Button } from "@faraday-gitlab/bpfd-portal";
import { FormattedMessage } from "react-intl";
import useCountries from "../../../hooks/useCountries";
import { Person } from "../../../lib/types";
import { HREF } from "../../../routes/routes";
import TaxException from "../../atoms/TaxException";

interface Props {
  user: Person;
}

const MyDataPaymentInformation: React.FC<Props> = ({ user }) => {
  const { countriesByUserLng } = useCountries();

  const bankAccount = user?.personalBankAccount;
  const country = countriesByUserLng.find((item) => item.countryCode === bankAccount?.countryCode);
  const bankAccountType = bankAccount?.isBankAccountEU ? "within" : "outside";

  const details = [
    {
      label: <FormattedMessage id="my-data.bank-account" />,
      input: bankAccount !== null ? <FormattedMessage id={`passing-away.${bankAccountType}-eu`} /> : "",
    },
    {
      label: <FormattedMessage id="my-data.bank-number" />,
      input: bankAccount?.bankAccount ?? "",
    },
    {
      label: <FormattedMessage id="bankAccount.bankName" />,
      input: bankAccount?.bankName ?? "",
    },
    {
      label: <FormattedMessage id="bankAccount.bankSwiftCode" />,
      input: bankAccount?.bankBicCode ?? "",
    },
    {
      label: <FormattedMessage id="bankAccount.bankAddress" />,
      input: bankAccount?.bankAddress ?? "",
    },
    {
      label: <FormattedMessage id="my-data.city-bank" />,
      input: bankAccount?.locationBank ?? "",
    },
    {
      label: <FormattedMessage id="bankAccount.bankLand" />,
      input: country?.countryName ?? "",
    },
    {
      label: (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <TaxException />
        </div>
      ),
      input: <FormattedMessage id={`utils.${user?.hasTaxException ? "yes" : "no"}`} />,
    },
  ];
  return (
    <InformationPanel
      className="flex-1 h-full"
      header={<FormattedMessage id="my-data.payment-information" />}
      informationDetails={details}
      buttons={[
        <Button
          key="edit"
          size="md"
          text={<FormattedMessage id="utils.edit" />}
          href={HREF.PAYMENT_DETAILS}
        />,
      ]}
    />
  );
};

export default MyDataPaymentInformation;
