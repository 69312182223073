import { ReactNode } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { AcceptancePayload } from "../lib/types";
import { ExchangeType } from "../lib/enum";
import { svbLink, toeslagenLink } from "../utils/pensionLinks";
import { useIntlMessage, useIntlMessageInput } from "./useIntlMessage";
import { EMPTY_STRING } from "../lib/constants";

const useEarlierLater = ({
  partlyRetirementPercentage,
  partlyRetirementStartYear,
  partlyRetirementStartMonth,
  indicationEndEmployment,
  retirementStartYear,
  retirementStartMonth,
}: {
  partlyRetirementPercentage: number | null;
  partlyRetirementStartYear: number | null;
  partlyRetirementStartMonth: number | null;
  indicationEndEmployment: boolean;
  retirementStartYear: number | null;
  retirementStartMonth: number | null;
}) => {
  const intlMessage = useIntlMessageInput();
  let partlyText = "";
  let fullyText = "";

  if (indicationEndEmployment) return { partlyText, fullyText };

  const hasPartly = partlyRetirementPercentage && partlyRetirementStartYear;
  const hasPartlyMonths = partlyRetirementStartMonth && partlyRetirementStartMonth !== 0;
  const hasPartlyWithMonths = hasPartly && hasPartlyMonths;
  const hasPartlyWithoutMonths = hasPartly && !hasPartlyMonths;
  const hasFully = retirementStartYear;
  const hasFullyMonths = retirementStartMonth && retirementStartMonth !== 0;
  const hasFullyWithMonths = hasFully && hasFullyMonths;
  const hasFullyWithoutMonths = hasFully && !hasFullyMonths;

  if (hasPartlyWithMonths) {
    partlyText = intlMessage("scenario.earlierLater", {
      percent: partlyRetirementPercentage.toString(),
      years: partlyRetirementStartYear.toString(),
      months: partlyRetirementStartMonth.toString(),
    });
  } else if (hasPartlyWithoutMonths) {
    partlyText = intlMessage("scenario.earlierLater-without-month", {
      percent: partlyRetirementPercentage.toString(),
      years: partlyRetirementStartYear.toString(),
    });
  }

  if (hasFullyWithMonths) {
    fullyText = intlMessage("scenario.earlierLater-fully", {
      years: retirementStartYear.toString(),
      months: retirementStartMonth.toString(),
    });
  } else if (hasFullyWithoutMonths) {
    fullyText = intlMessage("scenario.earlierLater-fully-without-month", {
      years: retirementStartYear.toString(),
    });
  }

  return { partlyText, fullyText };
};

const useSurrenderText = ({
  retirementStartYear,
  retirementStartMonth,
  aowRetirementDate,
  retirementDate,
  indicationSurrender,
  convertSurrenderToNormal,
  surrenderAmount,
  indicationEndEmployment,
}: {
  retirementStartYear: number | null;
  retirementStartMonth: number | null;
  aowRetirementDate?: Date;
  retirementDate?: Date;
  indicationSurrender: boolean;
  convertSurrenderToNormal: boolean;
  surrenderAmount?: number;
  indicationEndEmployment: boolean;
}) => {
  const intlMessageInput = useIntlMessageInput();
  const intlMessage = useIntlMessage();
  const intl = useIntl();

  let surrender: string | ReactNode = EMPTY_STRING;

  if (indicationEndEmployment) return surrender;

  if (indicationSurrender && !convertSurrenderToNormal) {
    surrender = (
      <FormattedMessage
        id="scenario.surrender-default"
        values={{
          surchargesLink: (
            <Link to={toeslagenLink} target="_blank">
              <FormattedMessage id="planner.surrender.link.toeslagen" />
            </Link>
          ),
          svbLink: (
            <Link to={svbLink} target="_blank">
              <FormattedMessage id="planner.surrender.link.svb" />
            </Link>
          ),
        }}
      />
    );

    const hasStartMonth = retirementStartMonth && retirementStartMonth > 0;
    if (retirementStartYear) {
      surrender = intlMessageInput("scenario.surrender-the-onetime", {
        amount: surrenderAmount
          ? intl.formatNumber(surrenderAmount, { minimumFractionDigits: 2 })
          : EMPTY_STRING,
        years: retirementStartYear.toString(),
        months: hasStartMonth ? retirementStartMonth.toString() : EMPTY_STRING,
      });
    }

    if (aowRetirementDate?.toDateString() === retirementDate?.toDateString()) {
      surrender = intlMessage("scenario.surrender-the-onetime");
    }
  }

  return surrender;
};

const useHLLH = ({
  differentHeightsOverTime,
  differentHeightsEndYearInMonth,
}: {
  differentHeightsOverTime: string | null;
  differentHeightsEndYearInMonth: number | null;
}) => {
  const intlMessageInput = useIntlMessageInput();
  const intlMessage = useIntlMessage();

  if (differentHeightsOverTime && differentHeightsEndYearInMonth) {
    return intlMessageInput("scenario.hllh", {
      type: intlMessage(`scenario.hllh-${differentHeightsOverTime}`),
      months: differentHeightsEndYearInMonth.toString(),
    });
  }

  return "";
};

const useOPPP = ({
  exchangeEntitlementsOption,
  exchangeEntitlementsAmount,
}: {
  exchangeEntitlementsOption: string | null;
  exchangeEntitlementsAmount: number | null;
}) => {
  const intlMessageInput = useIntlMessageInput();
  const intlMessage = useIntlMessage();
  const intl = useIntl();

  if (!exchangeEntitlementsOption || !exchangeEntitlementsAmount) return "";

  if (exchangeEntitlementsOption === ExchangeType.OPPP) {
    return intlMessageInput("scenario.oppp", {
      amount: intl.formatNumber(exchangeEntitlementsAmount, { minimumFractionDigits: 2 }),
      main: intlMessage("scenario.oppp-retirement-pension"),
      sub: intlMessage("scenario.oppp-partner-pension"),
    });
  }

  if (exchangeEntitlementsOption === ExchangeType.PPOP) {
    return intlMessageInput("scenario.oppp", {
      amount: intl.formatNumber(exchangeEntitlementsAmount, { minimumFractionDigits: 2 }),
      main: intlMessage("scenario.oppp-partner-pension"),
      sub: intlMessage("scenario.oppp-retirement-pension"),
    });
  }

  return "";
};

const useBridging = ({ bridgingAmount }: { bridgingAmount: number | null }) => {
  const intlMessage = useIntlMessage();

  if (bridgingAmount && bridgingAmount > 0) {
    return intlMessage("scenario.bridging");
  }

  return "";
};

const useScenarioTexts = (payload: AcceptancePayload) => {
  const {
    partlyRetirementPercentage,
    partlyRetirementStartYear,
    partlyRetirementStartMonth,
    indicationEndEmployment,
    retirementStartYear,
    retirementStartMonth,
    indicationSurrender,
    aowRetirementDate,
    retirementDate,
    convertSurrenderToNormal,
    differentHeightsOverTime,
    differentHeightsEndYearInMonth,
    bridgingAmount,
    exchangeEntitlementsOption,
    exchangeEntitlementsAmount,
    surrenderAmount,
  } = payload;

  const earlierLater = useEarlierLater({
    partlyRetirementPercentage,
    partlyRetirementStartYear,
    partlyRetirementStartMonth,
    indicationEndEmployment,
    retirementStartYear,
    retirementStartMonth,
  });

  const surrender = useSurrenderText({
    retirementStartYear,
    retirementStartMonth,
    aowRetirementDate,
    retirementDate,
    indicationSurrender,
    convertSurrenderToNormal,
    surrenderAmount,
    indicationEndEmployment,
  });

  const hllh = useHLLH({ differentHeightsOverTime, differentHeightsEndYearInMonth });

  const oppp = useOPPP({ exchangeEntitlementsOption, exchangeEntitlementsAmount });

  const bridging = useBridging({ bridgingAmount });

  return {
    ...earlierLater,
    surrender,
    hllh,
    oppp,
    bridging,
  };
};

export default useScenarioTexts;
