import { ReactNode } from "react";

interface Props {
  children: ReactNode;
}

const StandardGrid: React.FC<Props> = ({ children }) => {
  return <div className="grid grid-cols-12 gap-x-0 md:gap-x-2 gap-y-2">{children}</div>;
};

export default StandardGrid;
