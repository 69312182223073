import { HREF } from "../routes/routes";
import { EMyActionOption, OpenActions } from "./enum";

export const EARLIER_LATER_FULLY = 100;
export const EMPTY_STRING = "";

export const LOGIN_WARNING = "Let op!";
export const LOGIN_WELCOME = "Inloggen bij Pensioenfonds Detailhandel";
export const EUROPEES_INLOGGEN = "Europees inloggen";
export const INLOGGEN_MET_DIGID = "Inloggen met DigiD";
export const CANNOT_CONNECT = "Lukt het inloggen niet? Neem dan contact met ons op";

export const NETHERLANDS_BRP = ["The Netherlands", "Nederland"];

export const DATE_FORMAT = {
  YYYYMMDD: "yyyy-MM-dd",
  DDMMYYYY: "dd/MM/yyyy",
};

// My actions ---

export const MYACTION_OPTION_LINK: Record<EMyActionOption, string> = {
  [EMyActionOption.COHABIT]: HREF.PARTNER_DETAILS_FORM,
  [EMyActionOption.END_COHABIT]: HREF.PARTNER_DETAILS_FORM,
  [EMyActionOption.EARLY_LATE]: HREF.PLANNER,
  [EMyActionOption.VALUE_TRANSFER]: HREF.VALUE_TRANSFER,
};

export const MYACTION_OPEN_ACTIONS: Record<OpenActions, string> = {
  [OpenActions.REQUEST_PENSION]: HREF.RETIREMENT_CHOICE,
  [OpenActions.SUBMIT_CHOICE]: HREF.VALUE_TRANSFER_APPROVAL,
  [OpenActions.SUBMIT_DETAILS]: HREF.PASSING_AWAY,
  [OpenActions.SUBMIT_DIVORCE]: HREF.SUBMIT_DIVORCE,
};

export const MYACTION_OPEN_ACTIONS_LABEL: Record<OpenActions, string> = {
  [OpenActions.REQUEST_PENSION]: "home.receive",
  [OpenActions.SUBMIT_CHOICE]: "home.end-employment",
  [OpenActions.SUBMIT_DETAILS]: "home.passing-away",
  [OpenActions.SUBMIT_DIVORCE]: "home.divorce",
};

export const INVALID_ADDRESS_REGEX = /[^a-zA-Zà-žÀ-Ž0-9\s&#(),.:;`´'‘‚’“„+"-/]/; // NOSONAR
export const ALLOWED_CHARACTERS_REGEX = /^[a-zA-Zà-žÀ-Ž0-9\s&#(),.:;`´\\'‘‚’“„+"\-/]{2,}$/; // NOSONAR

export const BANK_ACCOUNT_REGEX = /^[A-Z0-9]+$/;
export const BANK_ADDRESS_REGEX = /^.{2,}$/;
export const BANK_ACCOUNT_SPECIAL_CHARACTERS = /^.+$/;

export const BLOCK_SQL = /.*[="<>%[\]{}\\]+.*/;
export const BLOCK_SPECIAL_CHARACTERS = /[@!$€~³¤¼½¾‘+_0-9]/;
export const BLOCK_HTML = /<.*?>/;
export const BLOCK_EMOJI = /\p{Emoji}/u;

// passing away
export const enum PaymentOptionValue {
  InsideEU = "inside-eu",
  OutsideEU = "outside-eu",
}

export enum WZPValue {
  before2025 = 27,
  after2025 = 25,
}

// end passing away
