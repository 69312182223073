import { FormTextBlock, Popup } from "@faraday-gitlab/bpfd-portal";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { usePlanner } from "../../../context/PlannerContext";
import { Link } from "react-router-dom";
import { retiringSoonResourceLink } from "../../../utils/pensionLinks";
import { ParticipationStatus } from "../../../lib/enum";
import { EMPTY_STRING } from "../../../lib/constants";
import DataListRowWrapped from "../../atoms/DataListRowWrapped";

const PlannerOtherOptions: React.FC = () => {
  const { planner } = usePlanner() || {};
  const [info, setInfo] = useState(false);

  const isActive = planner?.participation?.status === ParticipationStatus.ACTIVE;
  const pension = planner?.pension;
  const employment = planner?.employment;

  const onClickHandler = () => {
    setInfo(!info);
  };

  const inputData = [
    {
      index: "0",
      label: <FormattedMessage id="planner.partner" />,
      object1: {
        strikethrough: false,
        value: pension?.rangesPP,
      },
    },
    {
      index: "1",
      label: <FormattedMessage id="planner.kid(s)" />,
      object1: {
        strikethrough: false,
        value: pension?.rangesWZP,
      },
    },
  ];

  return (
    <>
      <FormTextBlock
        heading={<FormattedMessage id="planner.divorce" />}
        body={<FormattedMessage id="planner.divorce-info" />}
      />
      <FormTextBlock
        heading={<FormattedMessage id="planner.disability" />}
        body={<FormattedMessage id="planner.disability-info" />}
      />
      <FormTextBlock
        heading={<FormattedMessage id="planner.deceased" />}
        body={
          <>
            <FormattedMessage id="planner.deceased-info" />
            <div style={{ marginTop: "-16px", marginBottom: "-32px" }}>
              <DataListRowWrapped
                header={EMPTY_STRING}
                inputData={inputData}
                columnVisibility={[true]}
                totalVisibleColumns={2}
              />
            </div>
          </>
        }
        onIconClick={onClickHandler}
        showIcon
      />
      {!isActive && (
        <FormTextBlock
          heading={<FormattedMessage id="planner.continue-work-header" />}
          body={
            <FormattedMessage
              id="planner.continue-work-contact"
              values={{ phoneNumber: employment?.providerParticipantPhoneNumber }}
            />
          }
        />
      )}

      <Popup
        open={info}
        body={
          <FormattedMessage
            id="planner.deceased-icon"
            values={{
              link: (
                <Link to={retiringSoonResourceLink} target="_blank">
                  <FormattedMessage id="planner.exchange.altText-link" />
                </Link>
              ),
            }}
          />
        }
        onClose={onClickHandler}
        popupVariant="information"
      />
    </>
  );
};

export default PlannerOtherOptions;
