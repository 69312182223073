import { DataOverview, DataOverviewPanel } from "@faraday-gitlab/bpfd-portal";
import { FormattedMessage } from "react-intl";
import { PensionOverviewNonRetired } from "./PensionOverviewNonRetired";
import { PensionOverviewRetired } from "./PensionOverviewRetired";
import { useQuery } from "@tanstack/react-query";
import { Pension } from "../../../lib/types";
import { getPension } from "../../../react-query/queries";
import { useQueryTokenRefresh } from "../../../hooks/useQueryTokenRefresh";
import { pensionsKeys } from "../../../react-query/query-keys";

const PensionOverview: React.FC = () => {
  const {
    data: pension,
    isLoading,
    error,
    refetch,
  } = useQuery<Pension>({
    queryKey: pensionsKeys.all,
    queryFn: getPension,
  });

  useQueryTokenRefresh(error, refetch);

  const isRetired = !!pension?.isRetired;
  const retiredPension = pension?.retiredPension ?? null;
  const nonRetiredPension = pension?.nonRetiredPension ?? null;

  const hasRetiredPension = !!retiredPension && isRetired;
  const hasNonRetiredPension = !!nonRetiredPension && !isRetired;
  const isEmpty = pension === undefined || (retiredPension === null && nonRetiredPension === null);

  if (isLoading) {
    return (
      <DataOverviewPanel
        dataOverviews={
          <DataOverview
            ariaLabel=""
            badgeTitle="Loading"
            badges={[{ label: "", value: "" }]}
            subtitle={<FormattedMessage id="home.pension-your" />}
          />
        }
        className="h-full"
      />
    );
  }

  if (isEmpty) {
    return (
      <DataOverviewPanel
        dataOverviews={
          <DataOverview
            ariaLabel=""
            badgeTitle={<FormattedMessage id="home.no-pension-data" />}
            badges={[]}
            subtitle=""
          />
        }
      />
    );
  }

  return (
    <>
      {hasNonRetiredPension && (
        <PensionOverviewNonRetired
          key={1}
          hasNonRetiredPension={hasNonRetiredPension}
          nonRetiredPension={nonRetiredPension}
        />
      )}
      {hasRetiredPension && (
        <PensionOverviewRetired
          key={1}
          hasRetiredPension={hasRetiredPension}
          retiredPension={retiredPension}
        />
      )}
    </>
  );
};

export default PensionOverview;
