import React, { ReactNode } from "react";

interface Props {
  children: ReactNode;
}

const FlexContainer: React.FC<Props> = ({ children }) => {
  return <div className="container py-20 flex-grow">{children}</div>;
};

export default FlexContainer;
