import { InformationPanel } from "@faraday-gitlab/bpfd-portal";
import { ReactNode } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Person } from "../../../lib/types";

interface DivorcePersonProps {
  personInfo: Person;
  headerPanel: ReactNode;
}

const PersonInfo = ({ personInfo, headerPanel }: DivorcePersonProps) => {
  const intl = useIntl();
  return (
    <InformationPanel
      className="flex-1 h-full"
      header={headerPanel}
      informationDetails={[
        {
          input: personInfo?.name,
          label: <FormattedMessage id="utils.name" />,
        },
        {
          input: personInfo?.gender && <FormattedMessage id={`utils.gender.${personInfo.gender}`} />,
          label: <FormattedMessage id="utils.gender" />,
        },
        {
          input: intl.formatDate(personInfo?.birthdate ?? ""),
          label: <FormattedMessage id="utils.birthdate" />,
        },
        {
          input: personInfo?.bsn,
          label: <FormattedMessage id="utils.BSN" />,
        },
        {
          input: personInfo?.phone,
          label: <FormattedMessage id="utils.phone" />,
        },
        {
          input: personInfo?.email,
          label: <FormattedMessage id="utils.email" />,
        },
      ]}
    />
  );
};

export default PersonInfo;
