import { UseMaximumExchangeAow } from "../lib/enum";
import { ExchangeArrayItem, GenericRadioItem, RetirementPayload, RetirementRenamed } from "../lib/types";
import { apiKeys } from "./renameKeys";

interface SurrenderItem {
  isRefrainFromSurrenderSelected?: boolean;
  isSurrenderSelected?: boolean;
  ageMonth?: number;
  ageYear?: number;
  calendarMonth?: number;
  calendarYear?: number;
}

const handleSurrender = (item: SurrenderItem) => {
  const {
    isRefrainFromSurrenderSelected,
    ageMonth: _ageMonth,
    ageYear: _ageYear,
    calendarMonth: _calendarMonth,
    calendarYear: _calendarYear,
    isSurrenderSelected: _isSurrenderSelected,
    ...rest
  } = item;

  return {
    ...(isRefrainFromSurrenderSelected !== undefined && {
      isSelected: isRefrainFromSurrenderSelected,
    }),
    ...rest,
  };
};

interface EarlyOrLaterItem {
  isEarlierOrLaterRetirementSelected?: boolean;
  fullyOrPartial?: { value: number };
  fullCalendarMonth?: number;
  fullCalendarYear?: number;
  fullAgeYear?: number;
  fullAgeMonth?: number;
  partialAgeYear?: number;
  partialAgeMonth?: number;
  partialCalendarMonth?: number;
  partialCalendarYear?: number;
}

const handleEarlierLaterSelected = ({
  isEarlierOrLaterRetirementSelected,
  isSurrenderSelected,
  surrenderMonth,
  surrenderYear,
}: {
  isEarlierOrLaterRetirementSelected?: boolean;
  isSurrenderSelected?: boolean;
  surrenderMonth?: number;
  surrenderYear?: number;
}) => {
  if (isSurrenderSelected) return true;
  return isEarlierOrLaterRetirementSelected ?? !!(surrenderMonth ?? surrenderYear);
};

const handleEarlyOrLater = (
  item: EarlyOrLaterItem,
  surrenderItem: SurrenderItem,
  surrenderMonth?: number,
  surrenderYear?: number
) => {
  const {
    isEarlierOrLaterRetirementSelected,
    fullyOrPartial,
    fullCalendarMonth: _fullCalendarMonth,
    fullCalendarYear: _fullCalendarYear,
    fullAgeYear,
    fullAgeMonth,
    partialAgeYear,
    partialAgeMonth,
    partialCalendarMonth: _partialCalendarMonth,
    partialCalendarYear: _partialCalendarYear,
    ...rest
  } = item;
  const isSelected = handleEarlierLaterSelected({
    isEarlierOrLaterRetirementSelected,
    isSurrenderSelected: !!surrenderItem?.isSurrenderSelected,
    surrenderMonth,
    surrenderYear,
  });
  return {
    ...rest,
    ...(isEarlierOrLaterRetirementSelected !== undefined && {
      isSelected: isSelected,
    }),
    ...(fullyOrPartial?.value !== undefined && {
      percentage:
        fullyOrPartial?.value === 100 || fullyOrPartial?.value >= 10
          ? fullyOrPartial?.value
          : fullyOrPartial?.value * 10,
    }),
    ...{ fullyYears: fullAgeYear ?? surrenderYear ?? 0 },
    ...{ fullyMonths: fullAgeMonth ?? surrenderMonth ?? 0 },
    ...{ partlyYears: partialAgeYear ?? 0 },
    ...{ partlyMonths: partialAgeMonth ?? 0 },
  };
};

interface BridgingItem {
  maxExchangeAowAmount?: number;
  bridgingAmount?: number;
  isMaxExchangeAow?: GenericRadioItem[] | boolean;
}

const handleIsMaxExchangeAow = (isMaxExchangeAow?: boolean | GenericRadioItem[]) => {
  if (typeof isMaxExchangeAow === "boolean") return isMaxExchangeAow;

  return isMaxExchangeAow?.find((item) => item.checked)?.value === UseMaximumExchangeAow.YES;
};

const handleBridging = (item: BridgingItem) => {
  const {
    maxExchangeAowAmount: _maxExchangeAowAmount,
    bridgingAmount: exchangeAmount,
    isMaxExchangeAow,
    ...rest
  } = item;
  return {
    isMaxExchangeAow: handleIsMaxExchangeAow(isMaxExchangeAow),
    exchangeAmount,
    ...rest,
  };
};

interface HighLowItem {
  calendarMonth?: number;
  calendarYear?: number;
  numberOfMonths?: number;
  Exchange?: Record<string, ExchangeArrayItem>;
}

const handleHighLow = (item: HighLowItem) => {
  const {
    calendarMonth: _calendarMonth,
    calendarYear: _calendarYear,
    numberOfMonths,
    Exchange: highLowExchange,
    ...rest
  } = item;
  let type: string | undefined = undefined;
  if (highLowExchange) {
    const HLExchangeArray: ExchangeArrayItem[] = Object.values(highLowExchange);
    type = HLExchangeArray.find((item) => item.checked)?.value;
  }
  return {
    ...rest,
    ...(numberOfMonths !== undefined && { months: numberOfMonths }),
    ...(type !== undefined && { type }),
  };
};

interface ExchangeItem {
  exchangeType?: string | GenericRadioItem[] | null;
  exchangeAmount?: number;
  isMaxExchange?: GenericRadioItem[] | boolean;
}

const handleExchange = (item: ExchangeItem) => {
  const { isMaxExchange, exchangeType, ...rest } = item;

  let formattedIsMaxExchange;
  if (isMaxExchange && typeof isMaxExchange !== "boolean") {
    const exchangeArray = Object.values(isMaxExchange);
    const selectedValue = exchangeArray.find((item) => item.checked)?.value;

    if (selectedValue?.toLowerCase() === "yes") {
      formattedIsMaxExchange = true;
    } else {
      formattedIsMaxExchange = false;
    }
  }

  let formattedType;
  if (exchangeType && typeof exchangeType !== "string") {
    const typeArray = Object.values(exchangeType);
    formattedType = typeArray.find((item) => item.checked)?.value;
  }

  return {
    ...{ isMaxExchange: formattedIsMaxExchange ?? isMaxExchange },
    ...{ exchangeType: formattedType ?? exchangeType },
    ...rest,
  };
};

const formatRetirementToAPI = (retirement: RetirementRenamed): RetirementPayload => {
  const surrenderMonth = retirement.RefrainSurrender?.ageMonth;
  const surrenderYear = retirement.RefrainSurrender?.ageYear;
  const newRetirement = { ...retirement };

  for (const key in newRetirement) {
    if (Object.prototype.hasOwnProperty.call(newRetirement, key)) {
      switch (key) {
        case apiKeys.earlyOrLater:
          newRetirement[key] = handleEarlyOrLater(
            newRetirement[key],
            retirement[apiKeys.surrender],
            surrenderMonth,
            surrenderYear
          );
          break;
        case apiKeys.bridging:
          newRetirement[key] = handleBridging(newRetirement[key]);
          break;
        case apiKeys.highLow:
          newRetirement[key] = handleHighLow(newRetirement[key]) as HighLowItem;
          break;
        case apiKeys.exchange:
          newRetirement[key] = handleExchange(newRetirement[key]);
          break;
        case apiKeys.surrender:
          newRetirement[key] = handleSurrender(newRetirement[key]);
          break;
        default:
          break;
      }
    }
  }

  return newRetirement;
};

export default formatRetirementToAPI;
