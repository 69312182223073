import { PageLayout, TextHeader } from "@faraday-gitlab/bpfd-portal";
import { useQuery } from "@tanstack/react-query";
import { getSurvivors } from "../../react-query/queries";
import PassingAwayForm from "../forms/PassingAwayForm";
import Loading from "../organisms/Loading";
import RequestError from "../organisms/RequestError";
import { useState } from "react";
import SuccessContainer from "../molecules/passingAway/SuccessContainer";
import useScrollTo from "../../hooks/useScrollTo";
import { survivorsKeys } from "../../react-query/query-keys";

const PassingAway = () => {
  const { data, isLoading } = useQuery({
    queryKey: survivorsKeys.all,
    queryFn: getSurvivors,
  });
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const { ref, scrollToView } = useScrollTo<HTMLDivElement>();

  const survivor = data?.survivor;

  if (showSuccess) {
    return (
      <PageLayout>
        <TextHeader subText="" text="" />
        <SuccessContainer ref={ref} />;
      </PageLayout>
    );
  }

  if (isLoading) {
    return <Loading />;
  }

  if (!survivor) {
    return <RequestError />;
  }

  return (
    <PassingAwayForm
      survivor={survivor}
      onSuccess={() => {
        setShowSuccess(true);
        scrollToView();
      }}
    />
  );
};

export default PassingAway;
