import { ReactNode } from "react";

interface Props {
  children: ReactNode;
}

const StandardGridColumn: React.FC<Props> = ({ children }) => {
  return <div className="col-span-12 lg:col-span-5 lg:odd:col-start-2">{children}</div>;
};

export default StandardGridColumn;
