import { DataListRow } from "@faraday-gitlab/bpfd-portal";
import { Row } from "@faraday-gitlab/bpfd-portal/dist/app/components/atoms/dataListRow/DataListRow.types";
import { ReactNode } from "react";

interface Props {
  className?: string;
  header: string | ReactNode;
  inputData: Row[];
  columnVisibility?: boolean[];
  totalVisibleColumns?: number;
}

// wrapper necessary to avoid browser error when using DataListRow
const DataListRowWrapped: React.FC<Props> = ({
  className,
  header,
  inputData,
  columnVisibility,
  totalVisibleColumns,
}) => {
  return (
    <table>
      <thead>
        <DataListRow
          className={className}
          header={header}
          inputData={inputData}
          columnVisibility={columnVisibility}
          totalVisibleColumns={totalVisibleColumns}
        />
      </thead>
    </table>
  );
};

export default DataListRowWrapped;
