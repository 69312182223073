import { Header, TextHeader } from "@faraday-gitlab/bpfd-portal";
import { Link, useNavigate } from "react-router-dom";
import { HREF } from "../../routes/routes";
import PageBackground from "../templates/PageBackground";

const NotFoundPage = () => {
  const navigate = useNavigate();

  return (
    <PageBackground>
      <Header
        clickLogo={() => navigate(HREF.HOME)}
        headerButtonHandler={() => {
          // do nothing.
        }}
        headerButtonsData={[]}
        links={[]}
        onNavClick={() => {
          // do nothing.
        }}
      />
      <TextHeader text={"Pagina niet gevonden"} subText={<Link to={HREF.HOME}>Home pagina</Link>} />
    </PageBackground>
  );
};

export default NotFoundPage;
