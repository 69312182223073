import {
  FileUpload,
  FormSubmit,
  InformationPanel,
  PageLayout,
  Popup,
  Text,
  TextHeader,
} from "@faraday-gitlab/bpfd-portal";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { useAuthentication } from "../../context/AuthenticatedContext";
import { TUploadingDivorceDocSchema, useUploadingDivorceDocSchema } from "../../hooks/schemas/divorceSchema";
import { processDivorceCase, uploadFile } from "../../react-query/mutations";
import { getDivorce } from "../../react-query/queries";
import { HREF } from "../../routes/routes";
import { convertFileToBase64 } from "../../utils/file";
import PersonInfo from "../molecules/divorce/PersonInfo";
import { useQueryTokenRefresh } from "../../hooks/useQueryTokenRefresh";
import Loading from "../organisms/Loading";
import RequestError from "../organisms/RequestError";
import { handleExpiredToken, RetryCallback } from "../../utils/handleExpiredToken";
import FlexContainer from "../templates/FlexContainer";
import StandardGrid from "../templates/grid/StandardGrid";
import StandardGridColumn from "../templates/grid/StandardGridColumn";
import { divorceKeys, togglesKeys } from "../../react-query/query-keys";

const SubmitDivorce: React.FC = () => {
  const intl = useIntl();
  const data = useAuthentication();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    data: divorce,
    isLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: divorceKeys.all,
    queryFn: getDivorce,
  });

  useQueryTokenRefresh(error, refetch);
  const [showThankYouMessage, setShowThankYouMessage] = useState(false);

  const {
    mutate: mutateDivorce,
    isPending,
    error: postError,
  } = useMutation({
    mutationFn: processDivorceCase,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: togglesKeys.all,
      });
      setShowThankYouMessage(true);
    },
    onError: async (postError, data): Promise<void> => {
      handleExpiredToken(postError, mutateDivorce as RetryCallback, data);
    },
  });

  const {
    mutateAsync: mutateFileAsync,
    isPending: isUploadingFile,
    error: uploadingFileError,
  } = useMutation({
    mutationFn: uploadFile,
    onError: async (postError, data): Promise<void> => {
      handleExpiredToken(postError, mutateFileAsync as RetryCallback, data);
    },
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<TUploadingDivorceDocSchema>({
    mode: "onChange",
    resolver: zodResolver(useUploadingDivorceDocSchema()),
  });

  if (isLoading || data?.isLoading) {
    return <Loading />;
  }

  if (!data?.user || !divorce) {
    return <RequestError />;
  }

  const relationshipInfo = [
    {
      input: intl.formatDate(divorce?.person?.startDateSettlementPeriod),
      label: <FormattedMessage id="divorce.relationship-start-date" />,
    },
    {
      input: intl.formatDate(divorce?.person?.endDateSettlementPeriod),
      label: <FormattedMessage id="divorce.relationship-end-date" />,
    },
  ];

  const uploadGuideText = (
    <>
      <span>
        <FormattedMessage id="divorce.upload-documents-1" />
      </span>
      <br></br>
      <span>
        <FormattedMessage id="utils.is-required" />
      </span>
    </>
  );

  const uploadGuideText2 = <FormattedMessage id="divorce.upload-documents-2" />;

  const handleUploadFiles = async (files: File[]) => {
    return await Promise.all(
      files.map(async (item) => {
        const base64Content = await convertFileToBase64(item);
        if (base64Content === undefined) {
          throw new Error(`Failed to convert file ${item?.name} to base64`);
        }

        return mutateFileAsync({
          caseId: divorce?.person?.caseId,
          fileName: item?.name,
          documentType: "DivorceAgreementDocument",
          content: base64Content,
        });
      })
    );
  };

  const onSubmit = async (values: TUploadingDivorceDocSchema) => {
    const agreementDocs = (values.agreementDocs ?? []).concat(values.agreementDocs2 ?? []);
    if (agreementDocs?.length > 0) {
      await handleUploadFiles(agreementDocs);
    }
    mutateDivorce();
  };

  return (
    <PageLayout>
      <TextHeader
        subText={<FormattedMessage id="divorce.submit-subtitle" />}
        text={<FormattedMessage id="divorce.submit-title" />}
      />

      <FlexContainer>
        <StandardGrid>
          <StandardGridColumn>
            <PersonInfo personInfo={divorce?.person} headerPanel={<FormattedMessage id="app.mydata" />} />
          </StandardGridColumn>
          <StandardGridColumn>
            <PersonInfo
              personInfo={divorce?.partner}
              headerPanel={<FormattedMessage id="divorce.partner-info" />}
            />
          </StandardGridColumn>
          <div className="col-span-12 lg:col-span-10 lg:odd:col-start-2">
            <InformationPanel
              className="flex-1 h-full"
              header={<FormattedMessage id="divorce.relationship-info" />}
              informationDetails={relationshipInfo}
            />
          </div>
          <div className="col-span-10 col-start-2 py-10 font-normal">
            <Text as="h5" size="h5" className="font-semibold">
              <FormattedMessage id="divorce.settlement-text" />
            </Text>
            <Text as="h6" size="h6" wordBreak>
              <FormattedMessage id="divorce.sub-settlement-text" />
            </Text>
          </div>
          <div className="col-span-5 col-start-2">
            <form onSubmit={handleSubmit(onSubmit)}>
              <FileUpload
                id="agreementDocs"
                name="agreementDocs"
                text={uploadGuideText}
                className="min-w-fit min-h-fit"
                control={control}
                disabled={isPending || isUploadingFile}
                error={!!errors?.agreementDocs}
                feedbackMessage={errors?.agreementDocs?.message ?? uploadingFileError?.message}
              />
              <FileUpload
                id="agreementDocs2"
                name="agreementDocs2"
                text={uploadGuideText2}
                className="min-w-fit min-h-fit"
                control={control}
                disabled={isPending || isUploadingFile}
                error={!!errors?.agreementDocs2}
                feedbackMessage={errors?.agreementDocs2?.message ?? uploadingFileError?.message}
              />
              <FormSubmit
                className="border-none"
                buttons={[
                  {
                    size: "md",
                    ariaLabel: "button",
                    text: <FormattedMessage id="divorce.send-button" />,
                    type: "submit",
                    disabled: isUploadingFile || isPending,
                  },
                ]}
                errorMessage={postError?.message}
              />
            </form>
          </div>
        </StandardGrid>
      </FlexContainer>
      <Popup
        open={showThankYouMessage}
        body={<FormattedMessage id="divorce.thank-you-message" />}
        onClose={() => navigate(HREF.HOME)}
        popupVariant="information"
        preventBackdropClick
      />
    </PageLayout>
  );
};

export default SubmitDivorce;
