import {
  Button,
  DropdownItem,
  FormImportantMessage,
  LabeledText,
  Loader,
  Text,
} from "@faraday-gitlab/bpfd-portal";
import React from "react";
import { useIntlMessage } from "../../../hooks/useIntlMessage";
import useGenderOption from "../../../hooks/useGenderOption";
import { FormattedMessage } from "react-intl";
import { format, parseISO } from "date-fns";
import { DATE_FORMAT } from "../../../lib/constants";

interface PartnerDetailPreviewProps {
  loading?: boolean;
  error?: string;
  endDate?: string;
  firstName: string;
  lastName: string;
  middleName: string;
  dob: string;
  gender: string;
  bsn: string;
  startDate: string;
  onCancel: () => void;
  onSubmit: () => void;
}

const PartnerDetailPreview: React.FC<PartnerDetailPreviewProps> = ({
  loading,
  error,
  dob,
  gender,
  bsn,
  startDate,
  firstName,
  lastName,
  middleName,
  endDate,
  onCancel,
  onSubmit,
}) => {
  const intlMessage = useIntlMessage();
  const gender_list: DropdownItem[] = useGenderOption();

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      {error && error?.length > 0 && (
        <FormImportantMessage
          className="mb-6"
          heading={<FormattedMessage id="error.validation-fail.title" />}
          body={error}
        />
      )}
      {/* preview-wrapper */}
      <div className="bg-white p-6 rounded-md flex flex-col gap-4 shadow-lg">
        <LabeledText label={intlMessage("utils.firstName")} input={firstName} />
        <LabeledText label={intlMessage("utils.middle-name")} input={middleName} />
        <LabeledText label={intlMessage("utils.lastName")} input={lastName} />
        <div className="flex gap-2" />
        <LabeledText
          label={intlMessage("utils.birthdate")}
          input={format(parseISO(dob), DATE_FORMAT.DDMMYYYY)}
        />
        <LabeledText
          label={intlMessage("utils.gender")}
          input={gender_list.find((item) => item.value === gender)?.label ?? ""}
        />
        <LabeledText label={"BSN"} input={bsn} />
        <LabeledText
          label={intlMessage("partner.start.date")}
          input={format(parseISO(startDate), DATE_FORMAT.DDMMYYYY)}
        />
        {endDate && (
          <LabeledText
            label={intlMessage("partner.end.cohabitation.date")}
            input={format(parseISO(endDate), DATE_FORMAT.DDMMYYYY)}
          />
        )}
        <div
          className="bg-blue-extra-light rounded-md border-blue-dark"
          style={{
            padding: "4px 8px",
          }}>
          <Text size="quote">
            <FormattedMessage id="utils.confirmation" />
          </Text>
        </div>
        <div className="flex justify-center">
          <Button onClick={onCancel}>
            <FormattedMessage id="utils.no" />
          </Button>
          <Button disabled={loading} onClick={onSubmit}>
            <FormattedMessage id="utils.yes" />
          </Button>
        </div>
      </div>
    </>
  );
};

export default PartnerDetailPreview;
