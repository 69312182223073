import { addMonths } from "date-fns";
import { Retirement } from "../lib/types";

export const getRetirementLimits = ({
  retirement,
  participantDOB,
}: {
  retirement: Retirement;
  participantDOB: string | number | Date;
}) => {
  const dob = new Date(participantDOB);
  const maxDefaultRetirementAgeInMonths = retirement.maxDefaultRetirementAgeInMonths; // scheme retirement in months
  const maxRetirementAgeInMonths = retirement.maxRetirementAgeInMonths;
  const minRetirementAgeInMonths = retirement.minRetirementAgeInMonths;
  let minPartlyRetirementAgeInMonths = retirement.minRetirementAgeInMonths;
  if (retirement.minPartlyRetirementAgeInMonths) {
    minPartlyRetirementAgeInMonths = retirement.minPartlyRetirementAgeInMonths;
  }
  const minSurrenderAgeInMonths = retirement?.minSurrenderAgeInMonths;
  const defaultMaximumExchangeOP = retirement?.exchange?.maxExchangeOP ?? 0;
  const defaultMaximumExchangePP = retirement?.exchange?.maxExchangePP ?? 0;

  const latestAgeYears = Math.floor(maxRetirementAgeInMonths / 12);
  const latestAgeMonths = maxRetirementAgeInMonths % 12;
  const earliestAgeYears = Math.floor(minRetirementAgeInMonths / 12);
  const earliestAgeMonths = minRetirementAgeInMonths % 12;
  const earliestPartlyRetirementAgeYears = Math.floor(minPartlyRetirementAgeInMonths / 12);
  const earliestPartlyRetirementAgeMonths = minPartlyRetirementAgeInMonths % 12;
  const latestDefaultRetirementAgeYears = Math.floor(maxDefaultRetirementAgeInMonths / 12);
  const latestDefaultRetirementAgeMonths = maxDefaultRetirementAgeInMonths % 12;
  const latestDefaultRetirementCalendarYear = dob.getFullYear() + latestDefaultRetirementAgeYears;
  
  const earliestRetirementCalendarYear = addMonths(dob, minRetirementAgeInMonths).getFullYear();
  const latestRetirementCalendarYear = addMonths(dob, maxRetirementAgeInMonths).getFullYear();
  const earliestRetirementCalendarMonth = addMonths(dob, minRetirementAgeInMonths).getMonth();
  const latestRetirementCalendarMonth = addMonths(dob, maxRetirementAgeInMonths).getMonth();

  const earliestSurrenderRetirementAgeYears = minSurrenderAgeInMonths
    ? Math.floor(minSurrenderAgeInMonths / 12)
    : undefined;
  const earliestSurrenderRetirementAgeMonths = minSurrenderAgeInMonths
    ? minSurrenderAgeInMonths % 12
    : undefined;
  const earliestSurrenderRetirementCalendarYear = earliestSurrenderRetirementAgeYears
    ? dob.getFullYear() + earliestSurrenderRetirementAgeYears
    : undefined;

  return {
    earliestPartlyRetirementAgeYears,
    earliestPartlyRetirementAgeMonths,
    earliestPartlyRetirementCalendarYear: dob.getFullYear() + earliestPartlyRetirementAgeYears,
    earliestRetirementAgeYears: earliestAgeYears,
    earliestRetirementAgeMonths: earliestAgeMonths,
    earliestRetirementCalendarYear,
    latestRetirementAgeYears: latestAgeYears,
    latestRetirementAgeMonths: latestAgeMonths,
    latestRetirementCalendarYear,
    latestDefaultRetirementAgeYears,
    latestDefaultRetirementAgeMonths,
    earliestRetirementCalendarMonth,
    latestRetirementCalendarMonth,
    latestDefaultRetirementCalendarYear,
    earliestSurrenderRetirementAgeYears,
    earliestSurrenderRetirementAgeMonths,
    earliestSurrenderRetirementCalendarYear,
    defaultMaximumExchangeOP,
    defaultMaximumExchangePP,
  };
};
