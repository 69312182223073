import { useQuery } from "@tanstack/react-query";
import { useIntl } from "react-intl";
import { Country, CountryByUserLng } from "../lib/types";
import { getCountries } from "../react-query/queries";
import { useCallback, useMemo } from "react";
import { countriesKeys } from "../react-query/query-keys";

const useCountries = () => {
  const language = useIntl().locale;
  const { data, isPending } = useQuery({
    queryKey: countriesKeys.all,
    queryFn: getCountries,
  });

  const countries: Country[] = useMemo(() => {
    if (!data) return [];

    return data?.countries;
  }, [data]);

  const countriesByUserLng: CountryByUserLng[] = useMemo(() => {
    return countries
      .map((item) => ({
        countryCode: item.countryCode,
        countryName: item[`countryName${language.toUpperCase()}` as keyof Country],
      }))
      .sort((a, b) => a.countryName.localeCompare(b.countryName));
  }, [countries, language]);

  const countryInUserLngByEnglishName = useCallback(
    (countryName: string | null) => {
      const targetCountry = countries.find((country) => country.countryNameEN === countryName);
      return countriesByUserLng.find((country) => country.countryCode === targetCountry?.countryCode);
    },
    [countries, countriesByUserLng]
  );

  return {
    isPending,
    countries,
    countriesByUserLng,
    countryInUserLngByEnglishName,
  };
};

export default useCountries;
