import { FormTextBlock, FormField, Dropdown, Checkbox, Input, FormSubmit } from "@faraday-gitlab/bpfd-portal";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { FormattedMessage } from "react-intl";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { updateUserCorrespondenceSettings } from "../../../react-query/mutations";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate } from "react-router-dom";
import Loading from "../../organisms/Loading";
import { useAuthentication } from "../../../context/AuthenticatedContext";
import { Preferences } from "../../../lib/types";
import { HREF } from "../../../routes/routes";
import {
  TCorrespondenceDetailsSchema,
  useCorrespondenceDetailsSchema,
} from "../../../hooks/schemas/generalSchema";
import { CorrespondenceType } from "../../../lib/enum";
import { getCommunicationPreferences } from "../../../react-query/queries";
import { APILanguage } from "../../../utils/languageLoader";
import { useMemo } from "react";
import { useIntlMessage } from "../../../hooks/useIntlMessage";
import { getPreferencesOrDefault } from "../../../utils/defaultPreferences";
import { handleExpiredToken, RetryCallback } from "../../../utils/handleExpiredToken";
import StandardFormWrapper from "../../templates/StandardFormWrapper";
import { preferencesKeys, userKeys } from "../../../react-query/query-keys";

interface Props {
  preferences: Preferences;
  userId: string;
}

const UserCorrespondenceSettingsFormChild: React.FC<Props> = ({ preferences, userId }) => {
  const intlMessage = useIntlMessage();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const dropdownLanguage = useMemo(
    () => [
      {
        id: "1",
        key: "1",
        value: APILanguage.EN,
        label: intlMessage("utils.lang.en"),
      },
      {
        id: "2",
        key: "2",
        value: APILanguage.NL,
        label: intlMessage("utils.lang.nl"),
      },
    ],
    [intlMessage]
  );

  const dropdownCommunication = useMemo(
    () => [
      {
        id: "1",
        key: "1",
        value: CorrespondenceType.DIGITAL,
        label: intlMessage("my-data.correspondence-settings.way-of-communication.D"),
      },
      {
        id: "2",
        key: "2",
        value: CorrespondenceType.POST,
        label: intlMessage("my-data.correspondence-settings.way-of-communication.P"),
      },
    ],
    [intlMessage]
  );

  const {
    mutateAsync,
    isPending,
    error: postError,
  } = useMutation({
    mutationFn: updateUserCorrespondenceSettings,
    onSuccess: () => {
      if (getValues("language").value !== preferences?.language) {
        queryClient.invalidateQueries({
          queryKey: userKeys.all,
        });
      }

      queryClient.invalidateQueries({
        queryKey: preferencesKeys.user(userId),
      });
      navigate(HREF.MY_DATA_TAB_2);
    },
    onError: async (postError, data): Promise<void> => {
      handleExpiredToken(postError, mutateAsync as RetryCallback, data);
      setError("language", { message: postError?.message });
    },
  });

  const {
    handleSubmit,
    control,
    getValues,
    setError,
    reset,
    formState: { errors, isSubmitting, isDirty, isValid },
  } = useForm<TCorrespondenceDetailsSchema>({
    mode: "onChange",
    resolver: zodResolver(useCorrespondenceDetailsSchema()),
    resetOptions: { keepDefaultValues: false },
    defaultValues: {
      language: dropdownLanguage.find((option) => option.value === preferences.language),
      wayOfCommunication: dropdownCommunication.find(
        (option) => option.value === preferences.wayOfCommunication
      ),
      email: preferences?.email ?? "",
      isReceiveNews: preferences?.isReceiveNews ?? false,
    },
  });

  const onSubmit = async (data: FieldValues) => {
    await mutateAsync({
      language: data.language?.value,
      wayOfCommunication: data.wayOfCommunication?.value,
      email: data.email,
      isReceiveNews: data.isReceiveNews,
    } as Preferences);
    reset();
  };

  return (
    <StandardFormWrapper>
      <form onSubmit={handleSubmit((data) => onSubmit(data))}>
        <FormTextBlock heading={<FormattedMessage id="my-data.correspondence-settings" />} />
        <FormField
          className="col-span-12"
          id="language"
          labelText={<FormattedMessage id="my-data.correspondence-settings.language" />}>
          <Dropdown name="language" items={dropdownLanguage} control={control} />
        </FormField>
        <FormField
          className="col-span-12"
          id="wayOfCommunication"
          labelText={<FormattedMessage id="my-data.correspondence-settings.legal" />}>
          <Dropdown items={dropdownCommunication} name="wayOfCommunication" control={control} />
        </FormField>
        <FormField className="col-span-12" id="email" labelText={<FormattedMessage id="utils.email" />}>
          <Input
            id="email"
            name="email"
            control={control}
            placeholder={intlMessage("utils.email")}
            disabled={isSubmitting || isPending}
            error={!!errors.email}
          />
        </FormField>
        <FormField className="col-span-12" id="isReceiveNews">
          <Controller
            name="isReceiveNews"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Checkbox
                id="isReceiveNews"
                checked={value}
                onChange={onChange}
                label={<FormattedMessage id="my-data.correspondence-settings.newsletter" />}
              />
            )}
          />
        </FormField>
        <FormField id="language">
          <FormSubmit
            buttons={[
              {
                size: "md",
                ariaLabel: "button",
                text: <FormattedMessage id="utils.save" />,
                type: "submit",
                disabled: !isDirty || isSubmitting || !isValid,
              },
              {
                size: "md",
                ariaLabel: "link",
                onClick: () => {
                  navigate(-1);
                },
                text: <FormattedMessage id="utils.cancel" />,
              },
            ]}
            errorMessage={postError?.message}
          />
        </FormField>
      </form>
    </StandardFormWrapper>
  );
};

const UserCorrespondenceSettingsForm = () => {
  const data = useAuthentication();
  const { data: preferences, isLoading: comIsLoading } = useQuery({
    queryKey: preferencesKeys.user(data?.user?.id),
    queryFn: getCommunicationPreferences,
    select: (data) => getPreferencesOrDefault(data.preferences[0] as Preferences),
    enabled: !!data?.user?.id,
  });

  if (data?.isLoading || preferences === undefined || comIsLoading) {
    return <Loading />;
  }

  return <UserCorrespondenceSettingsFormChild preferences={preferences} userId={data?.user?.id ?? ""} />;
};

export default UserCorrespondenceSettingsForm;
