export enum Gender {
  FEMALE = "F",
  MALE = "M",
  UNKNOWN = "O", // Onbekend
}

export enum CorrespondenceType {
  DIGITAL = "D",
  POST = "P",
}

export enum MaritalStatus {
  MARRIAGE = "Marriage",
  PARTNERSHIP = "Partnership",
  COHABITATION = "Living together",
  CHILD = "Child",
}

export enum SituationCategory {
  DEFAULT = "Default",
  SALARY = "Salary",
  AOW = "AOW",
  PENSION = "Pension",
}

export enum SitName {
  SALARY = "salary",
  AOW = "aow",
  PENSION = "pension",
  SURRENDER = "surrender",
  TOTAL = "",
  DEFAULT = "DEFAULT",
}

export enum ParticipationStatus {
  ACTIVE = "Active",
  INACTIVE = "Inactive",
}

export enum ExchangeType {
  OPPP = "OPPP",
  PPOP = "PPOP",
}

export enum EmploymentOptionKeys {
  WorkingTimeOption = "workingTimeOption",
  EarningSalaryOption = "earningSalaryOption",
  QuittingWorkOption = "quittingWorkOption",
}

export enum YesNoValueEnum {
  YES = "yes",
  NO = "no",
}

export enum UseMaximumExchange {
  YES = "yes",
  NO = "no",
}

export enum HLLHType {
  HL = "HL",
  LH = "LH",
}

export enum UseMaximumExchangeAow {
  YES = "yesAow",
  NO = "noAow",
}

export enum EMyActionOption {
  EARLY_LATE,
  VALUE_TRANSFER,
  COHABIT,
  END_COHABIT,
}

export enum OpenActions {
  SUBMIT_CHOICE = "Submit choice",
  REQUEST_PENSION = "Request my pension",
  SUBMIT_DIVORCE = "Submit divorce",
  SUBMIT_DETAILS = "Submit details",
}

export enum PensionPlannerCategory {
  DEFAULT = "default",
  AOW = "aow",
  PENSION = "pension",
  CHOICE = "choice",
}

export enum ButtonType {
  SUBMIT = "submit",
  BUTTON = "button",
}

export enum RetirementChoiceScreen {
  REQUEST_RETIREMENT = "requestRetirement",
  SURRENDER_CHOICE = "surrenderChoice",
  ENTITLEMENT_CHOICE = "entitlementChoice",
  CURRENT_SCENARIO = "currentScenario",
}

export enum MYDATA_ACTIVE_KEY {
  DEFAULT = 0,
  SETTINGS = 1,
  BANK_ACCOUNT = 2,
}

export enum ButtonAction {
  UPDATE_CHOICE,
  UPSERT_CHOICE_PENSION,
  UPSERT_CHOICE_AOW,
  UPSERT_CHOICE_DEFAULT,
  DELETE_CHOICE,
  SURRENDER_CHOICE_PENSION,
  SURRENDER_CHOICE_AOW,
  SURRENDER_CHOICE_DEFAULT,
  DELETE_CHOICE_SURRENDER,
  GO_TO_PLANNER,
  DELETE_SCENARIO,
  TEXT_SURRENDER,
  UPSERT_CHOICE,
  TEXT_SCENARIO,
}

export enum AcceptanceStatus {
  REJECTED = "Rejected",
  ACCEPTED_PARTICIPANT = "AcceptedByParticipant",
  ACCEPTED_PARTNER = "AcceptedByPartner",
}

export enum Designation {
  PARTICIPANT = "E",
  PARTNER = "P",
  PARTNER_PARTICIPANT = "V",
  PARTICIPANT_PARTNER = "N",
}
