import { IntroPanel } from "@faraday-gitlab/bpfd-portal";
import { forwardRef } from "react";
import { FormattedMessage } from "react-intl";

const SuccessContainer = (_: unknown, ref: React.Ref<HTMLDivElement>) => {
  return (
    <div ref={ref} className="grid grid-cols-12 mt-4">
      <div className="col-span-10 col-start-2">
        <IntroPanel body={<FormattedMessage id="passing-away.thanks" />} introductionText="" />
      </div>
    </div>
  );
};

export default forwardRef(SuccessContainer);
