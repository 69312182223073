import { Loader } from "@faraday-gitlab/bpfd-portal";

const SpinningWheel: React.FC = () => {
  return (
    <div className="flex justify-center h-full" style={{ height: "300px" }}>
      <Loader width="h-20" height="w-20" />
    </div>
  );
};

export default SpinningWheel;
