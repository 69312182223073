import { FormField, Input, FormImportantMessage } from "@faraday-gitlab/bpfd-portal";
import { useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useEffect } from "react";
import { usePlanner } from "../../../context/PlannerContext";
import { ageToCalendarCalculation, calendarToAgeCalculation } from "../../../utils/ageCalendarConverter";
import PlannerMonthDropdown from "../../atoms/PlannerMonthDropdown";
import PlannerCellHeader from "../../atoms/PlannerCellHeader";
import PlannerCell from "../../atoms/PlannerCell";

const WorkingTimeOption = () => {
  const planner = usePlanner()?.planner;
  const intl = useIntl();
  const {
    control,
    getValues,
    setValue,
    watch,
    formState: { isSubmitting },
  } = useFormContext();

  const hoursKey = "employmentOptions.workingTimeOption.hours";
  const ageYearKey = "employmentOptions.workingTimeOption.ageYear";
  const ageMonthKey = "employmentOptions.workingTimeOption.ageMonth";
  const calendarYearKey = "employmentOptions.workingTimeOption.calendarYear";
  const calendarMonthKey = "employmentOptions.workingTimeOption.calendarMonth";

  const [watchedHours, watchedAgeYear, watchedAgeMonth, watchedCalendarYear, watchedCalendarMonth] = watch([
    hoursKey,
    ageYearKey,
    ageMonthKey,
    calendarYearKey,
    calendarMonthKey,
  ]);

  const isDisplayWarning = !watchedHours || !watchedAgeYear;
  const dateOfBirth = planner?.person.dateOfBirth ?? "";

  useEffect(() => {
    ageToCalendarCalculation(
      new Date(dateOfBirth),
      { year: Number(watchedAgeYear), month: Number(watchedAgeMonth ?? 0) },
      { yearKey: calendarYearKey, monthKey: calendarMonthKey },
      { getValues, setValue }
    );
  }, [watchedAgeYear, watchedAgeMonth, dateOfBirth, getValues, setValue]);

  useEffect(() => {
    calendarToAgeCalculation(
      new Date(dateOfBirth),
      { year: Number(watchedCalendarYear), month: Number(watchedCalendarMonth?.value) },
      { yearKey: ageYearKey, monthKey: ageMonthKey },
      { getValues, setValue }
    );
  }, [watchedCalendarYear, watchedCalendarMonth, dateOfBirth, getValues, setValue]);

  return (
    <>
      {isDisplayWarning && (
        <FormImportantMessage
          heading={<FormattedMessage id="home.intro-panel-warning" />}
          body={<FormattedMessage id="planner.warning.required-fields" />}
          className="col-span-12 mb-15"
        />
      )}
      <FormField
        className="col-span-12"
        id={hoursKey}
        labelText={<FormattedMessage id="planner.working-hour-label" />}>
        <Input
          id={hoursKey}
          name={hoursKey}
          placeholder={intl.formatMessage({ id: "planner.hour-placeholder" })}
          control={control}
          disabled={isSubmitting}
        />
      </FormField>
      <PlannerCell>
        <PlannerCellHeader heading={<FormattedMessage id="planner.from-what" />} />
        <FormField
          className="col-span-6"
          id={ageYearKey}
          labelText={<FormattedMessage id="planner.year-label" />}>
          <Input
            id={ageYearKey}
            name={ageYearKey}
            placeholder={intl.formatMessage({ id: "planner.year-placeholder" })}
            control={control}
            disabled={isSubmitting}
          />
        </FormField>
        <FormField
          className="col-span-6"
          id={ageMonthKey}
          labelText={<FormattedMessage id="planner.month-label" />}>
          <Input
            id={ageMonthKey}
            name={ageMonthKey}
            placeholder={intl.formatMessage({ id: "planner.month-placeholder" })}
            control={control}
            disabled={isSubmitting}
          />
        </FormField>
      </PlannerCell>
      <PlannerCell>
        <PlannerCellHeader heading={<FormattedMessage id="utils.per" />} />
        <PlannerMonthDropdown
          inputName={calendarMonthKey}
          placeholder={intl.formatMessage({ id: "planner.select-month" })}
          control={control}
        />
        <FormField
          className="col-span-6"
          id={calendarYearKey}
          labelText={<FormattedMessage id="planner.year-label" />}>
          <Input
            id={calendarYearKey}
            name={calendarYearKey}
            placeholder={intl.formatMessage({ id: "planner.select-year" })}
            control={control}
            disabled={isSubmitting}
          />
        </FormField>
      </PlannerCell>
    </>
  );
};

export default WorkingTimeOption;
