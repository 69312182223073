import { ReactNode } from "react";
import { Text } from "@faraday-gitlab/bpfd-portal";

interface PlannerCellHeaderProps {
  heading: string | ReactNode;
}

const PlannerCellHeader: React.FC<PlannerCellHeaderProps> = ({ heading }) => {
  return <Text className="col-span-12 font-semibold pb-2">{heading}</Text>;
};

export default PlannerCellHeader;
