import { ReactNode } from "react";

interface Props {
  children: ReactNode;
}

const PassingAwayFormWrapper: React.FC<Props> = ({ children }) => {
  return (
    <div className="container py-20 grid grid-cols-12">
      <div className="col-span-12 md:col-span-7 md:col-start-4">{children}</div>
    </div>
  );
};

export default PassingAwayFormWrapper;
