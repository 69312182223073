import { Button, FormField, Input, PlannerInputFields } from "@faraday-gitlab/bpfd-portal";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { usePlanner } from "../../../context/PlannerContext";
import { useSalarySchema } from "../../../hooks/schemas/generalSchema";
import { PlannerUpdatePayload } from "../../../lib/types";

interface PlannerPredictionHeaderProps {
  isActive: boolean;
}

interface EmploymentStringified {
  salary: string;
  hours: string;
}

const PlannerPredictionHeader: React.FC<PlannerPredictionHeaderProps> = ({ isActive }) => {
  const { planner, scenario, saveSalaryHours, isSavePending, errorSave, resetSaveFields, isDeletePending } =
    usePlanner() || {};
  const intl = useIntl();

  const salary = isActive ? planner?.employment.salary : scenario?.currentExternalSalary;
  const hours = isActive ? planner?.employment.hours : scenario?.currentExternalContractHours;

  const {
    handleSubmit,
    control,
    setError,
    formState: { isSubmitting, isValid, isSubmitted },
    reset,
  } = useForm({
    mode: "onChange",
    resolver: zodResolver(useSalarySchema()),
    defaultValues: {
      salary: salary?.toString() ?? "",
      hours: hours?.toString() ?? "",
    },
  });

  const disabledSaveButton = isSubmitting || isSavePending || (!isValid && !isSubmitted);
  const disabledInputFields = isSubmitting || isSavePending || isActive;

  useEffect(() => {
    reset({
      salary: isNaN(Number(salary)) ? "" : String(Number(salary)),
      hours: isNaN(Number(hours)) ? "" : String(Number(hours)),
    });
  }, [hours, reset, salary]);

  useEffect(() => {
    if (isDeletePending) {
      reset({
        salary: "",
        hours: "",
      });
    }
  }, [isDeletePending, reset]);

  useEffect(() => {
    if (errorSave) {
      setError("salary", { message: errorSave.message });
    }
  }, [errorSave, setError]);

  const submitFunction = async (data: EmploymentStringified) => {
    if (saveSalaryHours && resetSaveFields) {
      const newPlanner = {
        hours: Number(data.hours),
        salary: Number(data.salary),
        situations: planner?.situations.map(
          ({
            totalCalculatedGross: _totalCalculatedGross,
            totalCalculatedNet: _totalCalculatedNet,
            totalCurrentGross: _totalCurrentGross,
            totalCurrentNet: _totalCurrentNet,
            ...keepAttrs
          }) => {
            return {
              ...keepAttrs,
              amounts: keepAttrs.amounts.map(
                ({
                  optionInfo: _optionInfo,
                  isTotal: _isTotal,
                  calculatedGross: _calculatedGross,
                  calculatedNet: _calculatedNet,
                  currentNet: _currentNet,
                  ...keepAttrs
                }) => keepAttrs
              ),
            };
          }
        ),
      };
      await saveSalaryHours(newPlanner as PlannerUpdatePayload);
      resetSaveFields();
    }
  };

  return (
    <PlannerInputFields
      buttonComponent={
        isActive ? undefined : (
          <Button
            ariaLabel="button"
            onClick={handleSubmit((data) => submitFunction(data))}
            size="md"
            target="_self"
            text={<FormattedMessage id="utils.save" />}
            type="button"
            disabled={disabledSaveButton}
          />
        )
      }
      inputFields={
        <>
          <FormField id="gross" labelText={<FormattedMessage id="planner.header-gross-salary" />}>
            <Input
              id="salary"
              name="salary"
              control={control}
              placeholder="€"
              disabled={disabledInputFields}
            />
          </FormField>
          <FormField id="hours" labelText={<FormattedMessage id="planner.header-work-hours" />}>
            <Input
              id="hours"
              name="hours"
              control={control}
              placeholder={intl.formatMessage({ id: "utils.hours" })}
              disabled={disabledInputFields}
            />
          </FormField>
        </>
      }
    />
  );
};

export default PlannerPredictionHeader;
