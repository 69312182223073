import { ReactNode } from "react";

interface Prop {
  children: ReactNode;
}

export const GenericTableDiv = ({ children }: Prop) => {
  return <div style={{ marginBottom: "2rem", lineHeight: "2" }}>{children}</div>;
};

export default GenericTableDiv;
