import { Icon, Popup } from "@faraday-gitlab/bpfd-portal";
import { useState } from "react";
import { FormattedMessage } from "react-intl";

const TaxException: React.FC = () => {
  const [info, setInfo] = useState<boolean>(false);
  return (
    <>
      <FormattedMessage id="passing-away.wage-tax-question" />
      <button onClick={() => setInfo(true)} aria-label="Information" className="focus:outline-none">
        <Icon
          color="fill-black"
          hoverColor="hover:fill-black group-hover:fill-black"
          icon="information"
          size="sm"
        />
      </button>
      {info && (
        <Popup
          open={info}
          body={<FormattedMessage id="my-data.edit-payment.tax-information" />}
          onClose={() => setInfo(false)}
          popupVariant="information"
        />
      )}
    </>
  );
};

export default TaxException;
